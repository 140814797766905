import {
    faChevronRight,
    faCheck,
    faTimes,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./select.module.scss";

interface ISelect {
    label?: ReactNode;
    multi?: boolean;
    selectedOption?: any;
    options: Array<any>;
    optionAccessor: string;
    placeholder?: string;
    className?: string;
    name?: string;
    disabled?: boolean;
    onChange: (
        valObj: { [key: string]: string | string[] },
        id: string | string[],
        op: any
    ) => void;
    selectedId?: string | string[];
    loading?: boolean;
}

const Select: FC<ISelect> = (props) => {
    const {
        multi = false,
        label,
        selectedId,
        selectedOption,
        options,
        optionAccessor,
        placeholder = "Select",
        className = "",
        name,
        disabled,
        onChange,
        loading = false,
    } = props
    const selectRef = useRef<HTMLDivElement>(null);
    const [optionsList, setOptionsList] = useState<any[]>([]);
    const [open, setOpen] = useState<Boolean>(false);
    const [selected, setSelected] = useState<any[]>([]);

    const handleOpen = () => {
        if (disabled) return;
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isSelected = (op: any) => {
        return selected.find((_op) => _op.id === op.id);
    };
    const isAllSelected = () => {
        return selected.length === optionsList.length;
    };

    // HANDLE SELECTION
    const handleSelect = (op: any) => () => {
        let newOptions = [...selected, op];
        if (isSelected(op)) {
            newOptions = selected.filter((_op) => _op.id !== op.id);
        }
        if (multi) {
            onChange(
                {
                    [name || ""]: newOptions.map((op) => op.id),
                },
                newOptions.map((op) => op.id),
                newOptions
            );
        } else {
            onChange({ [name || ""]: op.id }, op.id, op);
            setOpen(false);
        }
        setSelected(newOptions);
    };
    // HANDLE ALL SELECTION
    const handleAllSelect = () => {
        if (isAllSelected()) {
            const newOptions: any[] = [];
            onChange(
                {
                    [name || ""]: newOptions.map((op) => op.id),
                },
                newOptions.map((op) => op.id),
                newOptions
            );
            setSelected(newOptions);
        } else {
            const newOptions = [...optionsList];
            onChange(
                {
                    [name || ""]: newOptions.map((op) => op.id),
                },
                newOptions.map((op) => op.id),
                newOptions
            );
            setSelected(newOptions);
        }
    };

    // SET OPTIONS TO COMPONENT STATE
    useEffect(() => {
        if (options) {
            setOptionsList(options);
        }
    }, [options]);

    // DEFAULT SELECTED OPTION
    useEffect(() => {
        // if (selectedOption) {
        if (selectedOption !== undefined && selectedOption.length > 0) {
            if (Array.isArray(selectedOption)) {
                setSelected(selectedOption);
            } else {
                setSelected([selectedOption]);
            }
        }
    }, [selectedOption]);
    useEffect(() => {
        if (selectedId !== null && selectedId !== undefined && selectedId !== '' && selectedId.length !== 0) {
            let id = selectedId;
            if (!Array.isArray(selectedId)) {
                id = [selectedId];
                const selectedOp = options.filter((op) => id.includes(op.id));
                setSelected(selectedOp);
            }
        } else {
            setSelected([]);
        }
    }, [selectedId, options]);

    // RENDER SELECTED OPTIONS IN SELECT BOX
    const renderLabel = () => {
        if (multi) {
            if (selected.length > 0) {
                return (
                    <ul className={styles.clip_wrapper}>
                        {selected.map((op) => {
                            return (
                                <li>
                                    {op[optionAccessor] || ""}{" "}
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faTimes}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const newOptions = selected.filter(
                                                (_op) => _op.id !== op.id
                                            );
                                            onChange(
                                                {
                                                    [name || ""]:
                                                        newOptions.map(
                                                            (op) => op.id
                                                        ),
                                                },
                                                newOptions.map((op) => op.id),
                                                newOptions
                                            );
                                            setSelected(newOptions);
                                        }}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                );
            }
            return placeholder;
        }
        return selected[0] && selected[0][optionAccessor]
            ? selected[0][optionAccessor]
            : placeholder;
    };

    // RENDER OPTIONS
    const renderOptions = () => {
        if (optionsList.length) {
            return (
                <>
                    {multi && (
                        <li
                            className={
                                isAllSelected() ? styles.active : undefined
                            }
                            onClick={handleAllSelect}
                        >
                            {isAllSelected() && (
                                <section>
                                    <FontAwesomeIcon size="xs" icon={faCheck} />
                                </section>
                            )}
                            Select All
                        </li>
                    )}
                    {optionsList.map((op) => (
                        <li
                            key={op.id}
                            id={op.id}
                            className={
                                isSelected(op) ? styles.active : undefined
                            }
                            onClick={handleSelect(op)}
                        >
                            {isSelected(op) && (
                                <section>
                                    <FontAwesomeIcon size="xs" icon={faCheck} />
                                </section>
                            )}
                            {op[optionAccessor]}
                        </li>
                    ))}
                </>
            );
        }
        return (
            <div style={{ height: "4rem", textAlign: "center" }}>
                No Options
            </div>
        );
    };

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (!selectRef.current?.contains(event.target as Node)) {
                setOpen(false);
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [selectRef]);

    return (
        <div
            ref={selectRef}
            className={[styles.select_wrapper, className].join(" ")}
        >
            {label && <label>{label}</label>}
            <div
                className={[
                    styles.label_area,
                    disabled && styles.disabled,
                    loading && styles.disabled,
                    multi && styles.multi,
                ].join(" ")}
                onClick={handleOpen}
            >
                {renderLabel()}
                {loading ? (
                    <FontAwesomeIcon
                        className={styles.loading_spinner}
                        size="xs"
                        icon={faSpinner}
                    />
                ) : (
                    <FontAwesomeIcon className={styles.chevron} size="xs" icon={faChevronRight} />
                )}
            </div>
            {open && (
                <>
                    <div
                        className={styles.backdrop}
                        onClick={handleClose}
                    ></div>
                    <section className={styles.options_wrapper}>
                        <ul>{renderOptions()}</ul>
                    </section>
                </>
            )}
        </div>
    );
};

export default Select;
