import React, { useEffect, useState } from "react";
import { useFormState, useQuery } from "../../../hooks";
import { axiosInstance } from "../../../state/axios";

import { orderBy, template } from "lodash-es";
import {
    fetchUserAtttandance,
    fetchUsersByRole,
} from "../../../state/actions/user.actions";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import {
    SUB_EMPLOYEE_USER_ROLES,
    ROLE_VS_PERMISSIONS,
    WEB_ROLES,
    defaultColsDef,
} from "../../../constants";
import TableHeader from "../../base/TableHeader";
import { customDateTimeFormat, generateDropDownOption, subtractNDays } from "../../../utils";
import Button from "../../base/Button";
import AttendanceSummary from "./summary";
import AgGridWrapper from "../../base/AgGridWrapper";
import { useAuthContext } from "../../../context/auth";

const dataColumnDefs = [
    {
        headerName: "Name",
        field: "name",
    },
    {
        headerName: "Depot",
        field: "depot_name",
    },
    {
        headerName: "Address",
        field: "depot_address",
    },
    {
        headerName: "Check-In Time",
        field: "check_in_time_ist",
        valueGetter: ({ data }: any) => {
            return customDateTimeFormat(data.check_in_time_ist);
        }
    },
    {
        headerName: "Check-Out Time",
        field: "check_out_time_ist",
        valueGetter: ({ data }: any) => {
            return customDateTimeFormat(data.check_out_time_ist);
        },
    },
    {
        headerName: "Visit Type",
        field: "check_in_type",
    },
    {
        headerName: "Status",
        field: "status",
    },

];
const UserAttandance = ({ selectedView }: { selectedView: string }) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const regionData: any[] = user?.region_details;
    const { loading, exec, data: userList = [] } = useQuery();
    const [gridApi, setSetGridApi] = useState<any>();
    const [usersListing, setUsersListing] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [formState, handleInputChange, resetState, setInitialState, dirty] =
        useFormState<{
            selectedRole: string;
            selectedUser: string;
            selectedRegion: Array<string>;
            attendance: string;
            visitType: string;
            startDate: string;
            endDate: string;
        }>({
            selectedRole: "",
            selectedUser: "",
            selectedRegion: [],
            attendance: "",
            visitType: "",
            startDate: subtractNDays(7).toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
        });

    const handleRegion = ({ selectedRegion }: any) => {
        handleInputChange({
            selectedUser: "",
            selectedRole: "",
            selectedRegion,
        });
        setUsersListing([]);
        // setRowData([]);
    };
    const handleRoleChange = ({ selectedRole }: any) => {
        handleInputChange({ selectedUser: "", selectedRole });
        exec(() =>
            fetchUsersByRole(user?.id, selectedRole, formState.selectedRegion)
        );
    };
    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        handleInputChange({
            startDate: new Date(newValue).toISOString().split("T")[0],
        });
    };
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        handleInputChange({
            endDate: new Date(newValue).toISOString().split("T")[0],
        });
    };

    const handleExport = () => {
        gridApi.exportDataAsCsv({
            processCellCallback(params: any) {
                let value = params.value;
                if (params.column.colDef.filter === "agDateColumnFilter") {
                    value = customDateTimeFormat(value);
                }
                return value === undefined ? "" : value;
            },
        });
    };

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
        setSetGridApi(params.api);
    };

    const handleSearch = () => {
        const payload = {
            region_id: formState.selectedRegion,
            userId: user?.id,
            roles: formState.selectedRole,
            selected_users: formState.selectedUser,
            status: formState.attendance,
            visit_type: formState.visitType,
            start_date: formState.startDate,
            end_date: formState.endDate,
        };
        fetchUserAtttandance(
            payload
        )
            .then((data) => {
                setRowData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        setUsersListing(userList);
    }, [userList]);


    const getUserRoleDropdown = () => {
        if (role === "Regional Manager") {
            return SUB_EMPLOYEE_USER_ROLES.filter(item => item !== "Regional Manager" && item !== "City Head").map((role: any) => ({
                label: role,
                id: role,
            }))
        }
        if (role === "City Head") {
            return SUB_EMPLOYEE_USER_ROLES.filter(item => item !== "City Head").map((role: any) => ({
                label: role,
                id: role,
            }))
        }
        return SUB_EMPLOYEE_USER_ROLES.map((role: any) => ({
            label: role,
            id: role,
        }))
    }

    return (
        <div>
            {ROLE_VS_PERMISSIONS[role]?.attendance_summary &&
                <AttendanceSummary />
            }
            <div className="table_filter_wrapper" style={{
                marginTop: '1rem'
            }}>
                <div className="table_filter table_filter_columns_4">
                    <div className="filter_field">
                        <label className="filter_field_label">Range</label>
                        <div className="filter_field_date filter_field_date_range">
                            <input
                                type={"date"}
                                name="startDate"
                                value={formState.startDate}
                                // min={
                                //     subtractNDays(
                                //         700,
                                //         new Date(formState.endDate)
                                //     )
                                //         .toISOString()
                                //         .split("T")[0]
                                // }
                                max={formState.endDate}
                                onChange={handleStartDateChange}
                            />
                            <input
                                type={"date"}
                                name="endDate"
                                value={formState.endDate}
                                min={formState.startDate}
                                // max={new Date().toISOString().split("T")[0]}
                                onChange={handleEndDateChange}
                            />
                        </div>
                    </div>
                    <div className="filter_field">
                        <Select
                            className="filter_field_select"
                            label="Region*"
                            multi={true}
                            name="selectedRegion"
                            selectedId={formState.selectedRegion}
                            optionAccessor="label"
                            onChange={handleRegion}
                            options={generateDropDownOption(regionData, "description")}
                            disabled={
                                !ROLE_VS_PERMISSIONS[role]?.report
                                    ?.regionDropdown
                            }
                        />
                    </div>
                    <div className="filter_field">
                        <Select
                            multi={false}
                            className=" "
                            // disabled={loading}
                            name="selectedRole"
                            selectedId={formState.selectedRole}
                            label="Role*"
                            optionAccessor="label"
                            onChange={handleRoleChange}
                            options={getUserRoleDropdown()}
                        />
                    </div>
                    <div className="filter_field">
                        <Select
                            className="filter_field_select"
                            multi={true}
                            // disabled={loading}
                            loading={loading}
                            name="selectedUser"
                            selectedId={formState.selectedUser}
                            label="User"
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={(
                                orderBy(usersListing, ["name"], ["asc"]) || []
                            ).map((user: any) => ({
                                label: user.name,
                                id: user.id,
                            }))}
                        />
                    </div>

                    <div className="filter_field">
                        <Select
                            className="filter_field_select"
                            label="Attendance Flag"
                            multi={true}
                            name="attendance"
                            selectedId={formState.attendance}
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={[
                                { label: "Present", id: "PRESENT" },
                                { label: "Absent", id: "ABSENT" },
                            ]}
                        />
                    </div>
                    <div className="filter_field">
                        <Select
                            className="filter_field_select"
                            label="Visit Type"
                            multi={true}
                            name="visitType"
                            selectedId={formState.visitType}
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={[
                                { label: "Field Visit", id: "Field Visit" },
                                { label: "Depot Visit", id: "Depot Visit" },
                            ]}
                        />
                    </div>
                </div>
                <div className="table_filter_button">
                    <Button onClick={handleSearch}>Search</Button>
                </div>
            </div>
            {/* {rowData && rowData.length > 0 && (
                <> */}
            <AgGridWrapper header={
                <>
                    {`${!!formState.selectedRole ? `${formState.selectedRole}:` : ""} ${usersListing?.find((item: any) => item.id === formState.selectedUser)?.name || ''
                        }`}

                </>
            }
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />
            {/* </>
            )} */}
        </div>
    );
};
export default UserAttandance;
