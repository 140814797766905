import { axiosInstance } from "../axios";

// GET PRINT ORDER SUMMARY
export const getPrintOrderSummary = (startDate: Date, userId?: string) => {
    return axiosInstance
        .get("/print/get-print-order-summary", {
            params: {
                id: userId,
                fromDate: startDate.toISOString().split("T")[0],
            },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// GET PRINT ORDER
export const getPrintOrder = (
    id: string,
    fromDate: string,
    toDate: string
) => {
    return axiosInstance
        .get(`/print/get-print-order-of-mapped-user/${id}`, {
            headers: { "Content-Type": "application/json" },
            params: {
                fromDate,
                toDate,
            },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PRINT ORDER STATUS
export const updatePrintOrderStatus = (payload: {
    user_id: string;
    print_order_id: string | number;
    status: string;
    remarks: string;
}) => {
    return axiosInstance
        .post("/print/update-print-order-status", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PRINT ORDER TRADE QTY
export const updatePrintOrderTrade = (payload: {
    user_id: string;
    print_order_id: string | number;
    status: string;
}) => {
    return axiosInstance
        .post("/print/update-print-order-trade", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};

// GET PRINT ORDER SAMPLE
export const getPrintOrderSample = (id?: string) => {
    return axiosInstance
        .get(`/sample/get-sample-copy-of-mapped-user/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PRINT ORDER STATUS SAMPLE
export const updatePrintOrderStatusSample = (payload: {
    user_id: string;
    print_order_id: string | number;
    status: string;
    remarks: string;
}) => {
    return axiosInstance
        .post("/sample/update-sample-copy-status", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PRINT ORDER TRADE QTY Sample
export const updatePrintOrderTradeSample = (payload: {
    user_id: string;
    print_order_id: string | number;
    status: string;
}) => {
    return axiosInstance
        .post("/sample/update-sample-copy-trade", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};
