import {
    faAngleDown,
    faAngleRight,
    faGripHorizontal,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode, useEffect, useState } from "react";

import logo from "../../../assets/icons/logo.svg";
// import reportIcon from "../../../assets/icons/newspaper.png";
import dashboardIcon from "../../../assets/icons/dashboard-1@2x.png";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import styles from "./sideNav.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES, WEB_ROLES } from "../../../constants";
import { useAuthContext } from "../../../context/auth";

interface ILiItem {
    title: string | ReactNode;
    onClick?: () => void;
    path?: string;
    icon?: IconDefinition;
    iconImage?: string;
    subItems?: Array<ILiItem>;
}
const LiComp: FC<ILiItem> = ({
    title,
    subItems,
    path,
    onClick,
    icon,
    iconImage,
}) => {
    const loc = useLocation();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const haveSubMenu = (subItems || [])?.length > 0;

    const renderSubMenu = () => {
        if (haveSubMenu && expanded)
            return (
                <ul>
                    {subItems?.map((item) => (
                        <LiComp {...item} />
                    ))}
                </ul>
            );
    };

    const handleClick = () => {
        if (path) {
            navigate(path);
        }
        if (onClick) {
            onClick();
        }
    };

    const renderIcon = () => {
        if (icon)
            return (
                <FontAwesomeIcon
                    icon={faGripHorizontal}
                    style={{ marginRight: 12, fontSize: "1.4rem" }}
                />
            );
        if (iconImage) return <img src={iconImage} />;
    };

    const liClass = [];

    if (haveSubMenu) liClass.push(styles.nested);
    if (expanded) liClass.push(styles.open);

    if (haveSubMenu && subItems?.find((p) => p.path === loc.pathname)) {
        liClass.push(styles.open);
    }

    if (loc.pathname === path) liClass.push(styles.active);

    useEffect(() => {
        if (haveSubMenu && subItems?.find((p) => p.path === loc.pathname)) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    }, [loc.pathname]);

    return (
        <li className={liClass.join(" ")} onClick={handleClick}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    {renderIcon()}
                    <div>{title}</div>
                </div>
                {haveSubMenu && (
                    <div onClick={() => setExpanded(!expanded)}
                        style={
                            {
                                padding: "5px",
                                cursor: 'pointer',
                                width: "20px"
                            }
                        }>
                        <FontAwesomeIcon
                            icon={expanded ? faAngleDown : faAngleRight}

                        />
                    </div>
                )}
            </div>
            {renderSubMenu()}
        </li>
    );
};

const SideNav = () => {
    const { user } = useAuthContext();

    const role: WEB_ROLES = user?.roles;
    const list: Array<ILiItem> = [
        {
            title: "Dashboard",
            iconImage: dashboardIcon,
            path: "/dashboard"
        },
        ...(role !== WEB_ROLES["Dispatch Executive"]
            ? [
                {
                    title: "Reports",
                    iconImage: dashboardIcon,
                    path: "/dashboard/reports",
                },
            ]
            : []),
        ...(role === WEB_ROLES["Admin"]
            ? [

                {
                    title: "Masters",
                    iconImage: dashboardIcon,
                    subItems: [
                        {
                            title: "Users",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/users",
                        },
                        {
                            title: "UnMapped Users",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/unmapped",
                        },
                        {
                            title: "Address",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/address",
                        },
                        {
                            title: "Device Registration",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/device",
                        },
                        {
                            title: "PO Revision Shift",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/revision",
                        },
                        {
                            title: "Attendance Radius",
                            iconImage: dashboardIcon,
                            path: "/dashboard/master/radius",
                        },

                    ],
                },
            ]
            : []),


    ];
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <img src={logo} loading="lazy" />
            </div>
            <div className={styles.details}>
                <img />
                <p>
                    <strong>{user?.name || "-"}</strong>
                </p>
                <p>{user?.email || "-"}</p>
                <p>{user?.roles || "-"}</p>
            </div>

            <SimpleBar autoHide={false} style={{ maxHeight: "calc(100vh - 290px)" }}>
                <div className={styles.listings}>
                    <ul>
                        {/* <li className={styles.text_labels}>
                        <div>Dashboard</div>
                        <div>The Indian Express</div>
                    </li> */}
                        {list.map((item, index) => <LiComp {...item} key={index} />)}
                    </ul>
                </div>
            </SimpleBar>
        </div>
    );
};

export default SideNav;
