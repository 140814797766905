export const addNDays = (days: number, addTo?: Date) => {
    var d = addTo || new Date();
    var ts = d.getTime();
    var days = ts + days * 24 * 60 * 60 * 1000;
    return new Date(days);
};

export const subtractNDays = (days: number, subtractTo?: Date) => {
    var d = subtractTo || new Date();
    var ts = d.getTime();
    var days = ts - days * 24 * 60 * 60 * 1000;
    return new Date(days);
};

// CUSTOM DATE TIME FORMAT
export const customDateTimeFormat = (newDate: any) => {
    if (newDate) {
        const date = new Date(`${newDate}`);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        // Custom format: "DD-MM-YYYY HH:MM:SS"
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return "-";
    }
};
// CUSTOM DATE FORMAT
export const customDateFormat = (newDate: any) => {
    if (newDate) {
        const date = new Date(`${newDate}`);
        let day: any = date.getDate();
        day = day < 10 ? `0${day}` : day;
        let month: any = date.getMonth();
        month = month < 9 ? `0${month + 1}` : month + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    } else {
        return "";
    }
};

// CUSTOM TIME FORMAT
export const customTimeFormat = (newDate: any) => {
    if (newDate) {
        const date = new Date(`${newDate}`);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    } else {
        return "-";
    }
};
export const convertMilliseconds = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
};

// PROPER CASE
// export const convertToProperCase = (text: string) => {
//     return text?.replace(/[_-]+/g, function (txt) {
//         return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
//     });
//     // .replace(/[_-]/g, " ");
// };
export const convertToProperCase = (text: string) => {
    return text
        ?.toLowerCase() // Convert the whole text to lowercase
        .replace(/[_-]+/g, " ") // Replace underscores and hyphens with spaces
        .replace(/\b\w/g, (txt) => txt.toUpperCase()); // Capitalize the first letter after every space
};

// GENERATE DROPDOWN OPTIONS
export const generateDropDownOption: any = (
    option: any[],
    field: string = "name"
) => {
    if (option && option.length > 0) {
        let data: any = option.map((el: any) => {
            return {
                label: field === "name" ? el.name : el.description,
                id: String(el.id),
                value: String(el.id),
            };
        });
        return data;
    }
};
