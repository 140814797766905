import { axiosInstance } from "../axios";

export const getAttendanceSummaryList = (startDate: Date, userId?: string) => {
    return axiosInstance
        .get("/attendance/summary", {
            params: {
                id: userId,
                startDate: startDate.toISOString().split("T")[0],
            },
        })
        .then(({ data }) => {
            return data;
        });
};
