import { axiosInstance } from "../axios";
// import useAuthStore from "../stores";

export enum STATUS {
    PENDING = "_0",
    APPROVE = "_1",
    REJECT = "_2",
}

export const getUnsoldReturnList = (payload: any) => {
    return axiosInstance
        .post("/transaction/unsold-return-web", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};

export const getDefaultUnsoldList = (
    startDate: Date,
    userId?: string,
    publicationId?: Array<string>
) => {
    return axiosInstance
        .post("/transaction/unsold-data-summary-web", {
            user_id: userId,
            start_date: startDate.toISOString().split("T")[0],
            publication_id: publicationId,
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getDefaultCollectionList = (startDate: Date, userId?: string) => {
    return axiosInstance
        .get("/transaction/collection-summary", {
            params: {
                isFilter: !!userId,
                userId: userId,
                startDate: startDate.toISOString().split("T")[0],
            },
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getCollectionList = (payload: any) => {
    return axiosInstance
        .post("/transaction/collection-web", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};

export const updateUnsoldReturns = (payload: any) => {
    return axiosInstance
        .post("/transaction/unsold-return", {
            ...payload,
            // updated_by_last_user_id: useAuthStore.getState().user?.id,
            approval_status: "_0",
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const updateCollection = (payload: any) => {
    return axiosInstance
        .post("/transaction/collection", {
            ...payload,
            // updated_by_last_user_id: useAuthStore.getState().user?.id,
        })
        .then(({ data }) => {
            return data.data;
        });
};

export const updateRecordStatus = (payload: {
    user_id: string;
    id: string | number;
    module: "unsold_return" | "collection";
    approval_status: STATUS;
    remarks: string;
}) => {
    return axiosInstance
        .post("/transaction/approval-status", {
            ...payload,
        })
        .then(({ data }) => {
            return data.data;
        });
};
