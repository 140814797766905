import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import TableHeader from "../TableHeader";
import { defaultColsDef } from "../../../constants";
import { AgGridReact } from "ag-grid-react";
import ExportExcel from "../../helper/ExportExcel";

export interface INTERFACE_AG_GRID_WRAPPER {
  rowData: Array<any>;
  columnDefs: Array<any>;
  header?: ReactNode;
  rightSection?: ReactNode;
  pagination?: boolean;
  exportData?: boolean;
  search?: boolean;
  fileName?: string;
}
const AgGridWrapper: FC<INTERFACE_AG_GRID_WRAPPER> = ({ rowData, columnDefs, header, rightSection, pagination, exportData = false, search = false, fileName = "export" }) => {
  const gridRef = useRef<AgGridReact>(null);
  const [csvHeaders, setCsvHeaders] = useState<Array<any>>([])
  useEffect(() => {
    const newColumnDef = columnDefs.map(el => ({
      ...el,
      header: el.headerName,
      key: el.field
    }))
    setCsvHeaders(newColumnDef);
  }, [columnDefs])


  const onGridReady = (params: any) => {
    // params.columnApi.autoSizeAllColumns();
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setGridOption(
      "quickFilterText",
      (document.getElementById("filter-text-box") as HTMLInputElement).value,
    );
  }, []);
  const createRightSection = () => {
    return <div style={{
      display: "flex",
      alignItems: "center",
      columnGap: "10px"
    }}>
      {search &&
        <div className="filter_field">
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search..."
            onInput={onFilterTextBoxChanged}
          />
        </div>
      }
      {exportData && <ExportExcel csvHeaders={csvHeaders} csvData={rowData} fileName={fileName.toLowerCase()} />}
    </div>
  }
  return <>
    <TableHeader
      header={header}
      rightSection={createRightSection()}
    />
    <div className="ag-theme-alpine ag_grid_table_wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={rowData || []}
        columnDefs={columnDefs}
        defaultColDef={defaultColsDef}
        onGridReady={onGridReady}
        tooltipShowDelay={0}
        tooltipHideDelay={3000}
        pagination={true}
        paginationPageSize={20}
      // paginationPageSizeSelector={[10, 20, 50, 100]}
      // autoSizeStrategy={autoSizeStrategy}
      />
    </div>
  </>
};

export default AgGridWrapper;
