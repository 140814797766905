import {
    faCheck,
    faCheckCircle,
    faPen,
    faTimesCircle,
    faReceipt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
    APPROVAL_STATUS,
    defaultColsDef,
    ROLE_VS_PERMISSIONS,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import {
    getCollectionList,
    STATUS,
    updateCollection,
    updateRecordStatus,
} from "../../../state/actions/dashboard.action";
import Button from "../../base/Button";
import Modal from "../../base/Modal";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import CollectionForm from "../../widgets/CollectionForm";
import UnsoldForm from "../../widgets/UnsoldForm";

import styles from "./collection.module.scss";
import {
    getCentersWeb,
    getParcelDepotsWeb,
} from "../../../state/actions/masters.action";
import {
    addNDays,
    customDateFormat,
    customDateTimeFormat,
    isEmptyObject,
    subtractNDays,
} from "../../../utils";
import TableHeader from "../../base/TableHeader";
import { axiosInstance } from "../../../state/axios";
import PaymentInfo from "../../widgets/PaymentInfo";
import ConfirmationModal from "../../base/ConfirmationModal";
import CollectionSummary from "./summary";
import AgGridWrapper from "../../base/AgGridWrapper";
import { useAuthContext } from "../../../context/auth";

const DashboardScreen = ({ selectedView }: { selectedView: string }) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.id;
    const regionData: any[] = user?.region_details;
    const [pageState, setPageState] = useFormState<{
        selectedView: string;
        fromDate: Date;
        toDate: Date;
        // categories: string[];
        // series: { name: string; data: any[]; type: string }[];
        showModal: boolean;
        showInfoModal: boolean;
        selectedRow: any;
        // showFilters: boolean;
        selectedBranch: Array<string>;
        selectedRegion: Array<string>;
        selectedCenter: Array<string>;
        selectedDepot: Array<string>;
        selectedPublication: string;
        publicationDropdownOption: Array<any>;
        filtersApplied: boolean;
        // CONFIRMATION DIALOG STATES
        actionId: string | number;
        actionStatus: STATUS,
        showConfirmationModal: boolean;
        remarks: string;
    }>({
        selectedView: "collection",
        fromDate: subtractNDays(7),
        toDate: new Date(),
        // categories: [],
        // series: [],
        showModal: false,
        showInfoModal: false,
        selectedRow: {},
        // showFilters: false,
        selectedBranch: [],
        selectedRegion: [],
        selectedCenter: [],
        selectedDepot: [],
        selectedPublication: "",
        publicationDropdownOption: [],
        filtersApplied: false,
        actionStatus: STATUS.PENDING,
        actionId: '',
        showConfirmationModal: false,
        remarks: "",
    });
    const { exec, data } = useQuery();
    const { exec: updateExec, loading } = useQuery();
    const { exec: execCenter, data: centerData } = useQuery();
    const { exec: execParcel, data: parcelData } = useQuery();

    const handleAction = (status: STATUS, id: string | number) => {
        if (status === STATUS.REJECT) {
            setPageState({ actionStatus: status, actionId: id, showConfirmationModal: true })
        } else {
            handleConfirmation(id, status)
        }

    };
    const handleConfirmation = (id: string | number, status: STATUS,) => {
        exec(() =>
            updateRecordStatus({
                user_id: userID,
                approval_status: status,
                id: id,
                module: "collection",
                remarks: pageState.remarks,
            }).then(() => {
                setPageState({ showConfirmationModal: false })
                fetchListing();
            })
        );

    };

    const collectionTableColConfig = [
        {
            headerName: "Date",
            field: "bill_till_date",
            minWidth: 120,
            valueGetter: ({ data }: any) => {
                return customDateFormat(data.bill_till_date);
            },
        },
        {
            headerName: "Submitted By",
            field: "user_name_created_by",
            minWidth: 120,
        },
        { headerName: "Ship to Party Code", field: "bill_to_code", minWidth: 120, },

        {
            headerName: "Parcel Point/Depot",
            field: "parcel_depot_name",
            minWidth: 150,
            valueGetter: (props: any) => {
                const { data } = props;
                return `${data.parcel_depot_name} (${data.ship_to_code})`;
            },
        },

        ...(ROLE_VS_PERMISSIONS[role]?.outstanding_collection.view
            ? [
                {
                    headerName: "Outstanding (₹)",
                    field: "closing_balance",
                    minWidth: 140,
                },
                {
                    headerName: "Collected (₹)",
                    field: "amount_collected",
                    minWidth: 120,
                },
            ]
            : []),

        {
            headerName: "Payment Mode",
            field: "payment_mode",
            minWidth: 150,
            cellRenderer: (props: any) => {
                const { data } = props;
                if (
                    data.payment_mode === "cash" ||
                    data.payment_mode === "online"
                ) {
                    return data.payment_mode;
                }
                else {
                    return (
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setPageState({
                                    showInfoModal: true,
                                    selectedRow: data,
                                });
                            }}
                        >
                            {`${data.payment_mode} ${data.payment_mode === "coupon"
                                ? "(" + data?.coupon_payment_dtos.length + ")"
                                : data.payment_mode === "cheque"
                                    ? "(1)"
                                    : ""
                                }`}
                        </span>
                    );
                }
            },
        },
        {
            headerName: "Status",
            field: "approval_status",
            minWidth: 140,
            valueGetter: (props: any) => {
                const { data } = props;
                const { approval_status } = data;
                if (approval_status == 0)
                    return "Pending"
                if (approval_status == 1) return "Approved"
                if (approval_status == 2) return "Rejected"
                if (approval_status == 3) return "Verified"
            },
            cellRenderer: (props: any) => {
                const { data } = props;
                const { approval_status } = data;
                if (approval_status == 0)
                    return (
                        <div className={`${styles.status} ${styles.pending}`}>
                            Pending
                        </div>
                    );
                if (approval_status == 1)
                    return (
                        <div className={`${styles.status} ${styles.success}`}>
                            Approved
                        </div>
                    );
                if (approval_status == 2)
                    return (
                        <div className={`${styles.status} ${styles.error}`}>
                            Rejected
                        </div>
                    );
                if (approval_status == 3)
                    return (
                        <div className={`${styles.status} ${styles.verify}`}>
                            Accepted by Collection Officer
                        </div>
                    );
            },
        },

        ...(ROLE_VS_PERMISSIONS[role]?.editTable.collection
            ? [
                {
                    headerName: "Edit",
                    field: "edit",
                    minWidth: 100,
                    cellRenderer: (props: any) => {
                        const { data } = props;
                        if (data.approval_status == 0)
                            return (
                                <FontAwesomeIcon
                                    className={styles.editIcon}
                                    icon={faPen}
                                    title={"Edit"}
                                    onClick={() => {
                                        setPageState({
                                            showModal: true,
                                            selectedRow: data,
                                        });
                                    }}
                                />
                            );
                        return null;
                    },
                },
            ]
            : []),

        ...(ROLE_VS_PERMISSIONS[role]?.showCollectionApprovals
            ? [
                {
                    headerName: "Actions",
                    field: "actions",
                    minWidth: 100,
                    cellRenderer: (props: any) => {
                        const { data } = props;
                        if (data.approval_status == 3)
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        onClick={() => handleAction(
                                            STATUS.APPROVE,
                                            data.collection_id
                                        )}
                                        title={"Approve"}
                                        size="2x"
                                        style={{
                                            color: "green",
                                            cursor: "pointer",
                                        }}
                                        icon={faCheckCircle}
                                    />
                                    <FontAwesomeIcon
                                        onClick={() => handleAction(
                                            STATUS.REJECT,
                                            data.collection_id
                                        )}
                                        size="2x"
                                        title={"Reject"}
                                        style={{
                                            color: "red",
                                            cursor: "pointer",
                                        }}
                                        icon={faTimesCircle}
                                    />
                                </div>
                            );
                        return null;
                    },
                },
            ]
            : []),
    ];

    const fetchListing = (withFilter: boolean = true) => {
        let payload = {
            is_filter: true,
            is_for_mobile: false,
            start_date: pageState.fromDate.toISOString().split("T")[0],
            end_date: pageState.toDate.toISOString().split("T")[0],
            user_id: userID,
            branch: pageState.selectedBranch,
            region_id: pageState.selectedRegion,
            center_id: pageState.selectedCenter,
            parcel_depot_id: pageState.selectedDepot,
            publication_id: [],
        }

        exec(() =>
            getCollectionList(payload)
        );
    };


    useEffect(() => {
        if (pageState.selectedRegion.length > 0) {
            execCenter(() => getCentersWeb(pageState.selectedRegion));
        }
        setPageState({ selectedDepot: [], selectedCenter: [] });
    }, [pageState.selectedRegion]);

    useEffect(() => {
        if (pageState.selectedCenter.length > 0) {
            execParcel(() => getParcelDepotsWeb(pageState.selectedCenter));
        }
        setPageState({ selectedDepot: [] });
    }, [pageState.selectedCenter]);

    useEffect(() => {
        fetchListing();
    }, [pageState.selectedView]);


    const handleSubmit = (type: "collection") => (data: any) => {
        updateExec(() =>
            updateCollection({ data, updated_by_last_user_id: userID }).then(() => {
                fetchListing();
                setPageState({
                    showModal: false,
                    selectedRow: {},
                });
            })
        );
    };

    const handleSearch = () => {
        fetchListing();
    };

    const handleBranchChange = (data: any) => {
        setPageState({ selectedBranch: data.selectedBranch })
    }
    const handleRegionChange = (data: any) => {
        setPageState({ selectedRegion: data.selectedRegion })
    }
    const handleDepotChange = (data: any) => {
        setPageState({ selectedDepot: data.selectedDepot })
    }
    const handleCenterChange = (data: any) => {
        setPageState({ selectedCenter: data.selectedCenter })
    }

    return (
        <>
            {role !== "Dispatch Executive" &&
                <CollectionSummary />
            }
            <div className="table_filter_wrapper" style={{
                marginTop: '1rem'
            }}>
                <div className="table_filter table_filter_columns_4">
                    <div className="filter_field">
                        <label className="filter_field_label">Range</label>
                        <div className="filter_field_date filter_field_date_range">
                            <input
                                type={"date"}
                                name="startDate"
                                value={
                                    pageState.fromDate
                                        .toISOString()
                                        .split("T")[0]
                                }
                                max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                    setPageState({
                                        fromDate: new Date(
                                            e.currentTarget.value
                                        ),
                                    });
                                }}
                            />
                            <input
                                type={"date"}
                                name="endDate"
                                value={
                                    pageState.toDate.toISOString().split("T")[0]
                                }
                                // max={new Date().toISOString().split("T")[0]}
                                min={
                                    addNDays(0, pageState.fromDate)
                                        .toISOString()
                                        .split("T")[0]
                                }
                                onChange={(e) => {
                                    setPageState({
                                        toDate: new Date(e.currentTarget.value),
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {!(role === "City Head" || role === "Regional Manager") &&
                        <div className="filter_field">
                            <Select
                                label="Branch *"
                                multi={true}
                                name="selectedBranch"
                                selectedId={
                                    pageState.selectedBranch
                                }
                                optionAccessor="label"
                                onChange={(data) => handleBranchChange(data)}
                                options={[
                                    { label: "Mumbai", id: "1" },
                                ]}
                            />
                        </div>
                    }
                    <div className="filter_field">
                        <Select
                            label="Region"
                            placeholder="Select Region"
                            name="selectedRegion"
                            multi={true}
                            className="filter_field_select"
                            optionAccessor="name"
                            onChange={(data) => handleRegionChange(data)}
                            selectedId={pageState.selectedRegion}
                            options={regionData || []}
                        />
                    </div>
                    <div className="filter_field">
                        <Select
                            label="Center"
                            multi={true}
                            name="selectedCenter"
                            disabled={!pageState.selectedRegion}
                            placeholder="Select Center"
                            className="filter_field_select"
                            optionAccessor="name"
                            onChange={(data) => handleCenterChange(data)}
                            selectedId={pageState.selectedCenter}
                            options={centerData || []}
                        />
                    </div>
                    <div className="filter_field">
                        <Select
                            label="Parcel/Depot"
                            multi={true}
                            name="selectedDepot"
                            disabled={!pageState.selectedCenter}
                            placeholder="Select Parcel/Depot"
                            className="filter_field_select"
                            optionAccessor="label"
                            onChange={(data) => handleDepotChange(data)}
                            selectedId={pageState.selectedDepot}
                            options={(parcelData || []).map((op: any) => ({
                                label: op.user_name,
                                id: op.user_id,
                            }))}
                        />
                    </div>

                </div>

                <div className="table_filter_button">
                    <Button onClick={handleSearch}>Search</Button>
                </div>
            </div>
            <AgGridWrapper
                header={"Collections"}
                rowData={data}
                columnDefs={collectionTableColConfig}
                exportData={false}
                fileName={"collection"}
                search={true}
            />
            <Modal
                header={<h3>Update Collection</h3>}
                show={pageState.showModal}
                onClose={() => setPageState({
                    showModal: false,
                    selectedRow: {},
                })}
            >
                <CollectionForm
                    role={role}
                    value={pageState.selectedRow}
                    onSubmit={handleSubmit("collection")}
                />

            </Modal>
            <Modal
                header={"Payment Info"}
                show={pageState.showInfoModal}
                onClose={() => setPageState({
                    showInfoModal: false,
                    selectedRow: {},
                })}
            >
                <PaymentInfo data={pageState.selectedRow} />
            </Modal>
            <ConfirmationModal
                header={"Remarks"}
                show={pageState.showConfirmationModal}
                onClose={() => setPageState({ showConfirmationModal: false })}
            >
                <div style={{ display: "flex", flexFlow: "column wrap" }}>
                    <input
                        type={"text"}
                        name="remarks"
                        onChange={(e) => {
                            setPageState({
                                remarks: e.currentTarget.value,
                            });
                        }}
                        placeholder="Please enter remarks"
                        style={{
                            height: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '4px 10px',
                        }}
                    />
                    <p style={{ margin: 0, marginTop: '.5rem' }}>You are about to perform an action that cannot be undone. Please confirm if you want to proceed.</p>
                    <div style={{ display: "flex", columnGap: "8px", justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button className="default" type="button" onClick={() => setPageState({ showConfirmationModal: false })}>
                            No
                        </Button>
                        <Button type="button" onClick={() => handleConfirmation(pageState.actionId, pageState.actionStatus)}>
                            Yes
                        </Button>
                    </div>
                </div>

            </ConfirmationModal>
        </>
    );
};

export default DashboardScreen;
