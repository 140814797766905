import React, { FC, ReactNode, useEffect } from "react";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import "./App.css";
// import CentersPage from "./components/pages/CentersPage";
import UnmappedUsers from "./components/pages/UnmappedUsers";
// import CitiesPage from "./components/pages/CitiesPage";
import AppLayout from "./components/pages/AppLayout";
import DashboardScreen from "./components/pages/DashboardScreen";
// import DistrictsPage from "./components/pages/DistrictsPage";
import LoginPage from "./components/pages/LoginPage";
// import RegionsPage from "./components/pages/RegionsPage";
// import {
//     default as PublicationPage,
//     default as StatesPage,
// } from "./components/pages/StatesPage";
// import UserAttandance from "./components/pages/UserAttandance";
import UserPage from "./components/pages/UserPage";
import AboutUs from "./components/widgets/AboutUs";
import ContactUs from "./components/widgets/ContactUs";
import PrivacyPolicy from "./components/widgets/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./components/widgets/ReturnPolicy";
import Terms from "./components/widgets/TermsandConditions";
// import PrintOrder from "./components/pages/PrintOrder";
// import PrintOrderSample from "./components/pages/PrintOrderSample";
import ReportsPage from "./components/pages/ReportsPage";
import AuthProvider, { useAuthContext } from "./context/auth";
import AppLoader from "./components/base/AppLoader";
import ParcelDepot from "./components/pages/ParcelDepot";
import DeviceRegistration from "./components/pages/DeviceRegistration";
import Revision from "./components/pages/Revision";
import Radius from "./components/pages/Radius";

const ProtectedRoute: FC<{
    component: ReactNode;
    authType: "PRE_AUTH" | "POST_AUTH";
}> = ({ component, authType }) => {
    const { isLoading, isLoggedIn } = useAuthContext();
    if (isLoading) {
        return <AppLoader />
    } else {
        if (authType === "PRE_AUTH") {
            if (isLoggedIn) {
                return <Navigate to="/" replace />
            }
            return <>{component}</>;
        }
        if (!isLoggedIn) {
            return <Navigate to="/login" replace />
        }
        return <>{component}</>;
    }
};

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="login"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<LoginPage />}
                            />
                        }
                    />
                    <Route
                        path="refund-policy"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<ReturnPolicy />}
                            />
                        }
                    />
                    <Route
                        path="terms"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<Terms />}
                            />
                        }
                    />
                    <Route
                        path="privacy-policy"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<PrivacyPolicy />}
                            />
                        }
                    />
                    <Route
                        path="contact-us"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<ContactUs />}
                            />
                        }
                    />
                    <Route
                        path="about-us"
                        element={
                            <ProtectedRoute
                                authType="PRE_AUTH"
                                component={<AboutUs />}
                            />
                        }
                    />
                    <Route
                        path="dashboard"
                        element={
                            <ProtectedRoute
                                component={<AppLayout />}
                                authType="POST_AUTH"
                            />
                        }
                    >
                        <Route path="master" element={<Outlet />}>
                            {/* DO NOT REMOVE */}
                            {/* <Route
                                path="states"
                                element={
                                    <ProtectedRoute
                                        component={<StatesPage />}
                                        authType="POST_AUTH"
                                    />
                                }
                            /> */}

                            {/* <Route path="cities" element={<CitiesPage />} />
                            <Route path="districts" element={<DistrictsPage />} />
                            <Route path="regions" element={<RegionsPage />} />
                            <Route path="centers" element={<CentersPage />} /> */}
                            <Route path="users" element={<UserPage />} />
                            <Route
                                path="unmapped"
                                element={<UnmappedUsers />}
                            />
                            <Route
                                path="address"
                                element={<ParcelDepot />}
                            />
                            <Route
                                path="revision"
                                element={<Revision />}
                            />
                            <Route
                                path="device"
                                element={<DeviceRegistration />}
                            />
                            <Route
                                path="radius"
                                element={<Radius />}
                            />
                            <Route
                                index
                                element={
                                    <Navigate
                                        to="/dashboard/master/states"
                                        replace
                                    />
                                }
                            />
                        </Route>
                        <Route path="reports" element={<ReportsPage />} />
                        <Route index element={<DashboardScreen />} />
                        {/* <Route path="*" element={<Navigate to={"/dashboard"} />} /> */}
                    </Route>
                    <Route
                        index
                        element={
                            <ProtectedRoute
                                authType="POST_AUTH"
                                component={<Navigate to="/dashboard" replace />}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to={"/login"} />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
