
import styles from "./appLoader.module.scss";
const AppLoader = () => {
  return (
    <div className={styles["app-loader"]}>
      <div className={styles['loader-spin']}>
        <span className={`${styles['loader-dot']} ${styles['loader-dot-spin']}`}>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

export default AppLoader;
