import { createContext, FC, useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../state/axios";

// Contexts
export const AuthContext = createContext();
export const AuthActionContext = createContext();

// Hooks to use contexts
export const useAuthContext = () => useContext(AuthContext);
export const useAuthActionContext = () => useContext(AuthActionContext);

const AuthProvider = ({ children }) => {
    const [authDetails, setAuthDetails] = useState({
        isLoading: true,
        isLoggedIn: false,
        user: null,
    });

    const [token, setToken] = useState(localStorage.getItem("token"));

    // Function to set the auth token
    const setAuthToken = async (token) => {
        if (token) {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
            delete axiosInstance.defaults.headers.common["Authorization"];
        }
    };

    // Function to fetch user profile
    const getUserProfile = async (token) => {
        try {
            const res = await axiosInstance.get(`auth/profile`);

            if (res.data.code === 200) {
                setAuthDetails({
                    isLoading: false,
                    isLoggedIn: true,
                    user: res.data.data,
                });
            } else {
                setAuthDetails({
                    isLoading: false,
                    isLoggedIn: false,
                    user: null,
                });
            }
        } catch (error) {
            setAuthDetails({
                isLoading: false,
                isLoggedIn: false,
                user: null,
            });
        }
    };

    // Signin function
    const Signin = async (payload) => {
        return axiosInstance
            .post(
                "/auth/login",
                { ...payload, platform: "web" },
                {
                    headers: {
                        "Content-Type": "application/vnd.tiedn.ie.api.v1+json",
                    },
                }
            )
            .then(({ data, headers }) => {
                const { "tiedn-ie-api-authorization": token } = headers;
                localStorage.setItem("token", token);
                // localStorage.setItem("user_data", JSON.stringify(data.data));
                setToken(token);
                return data;
            })
            .catch((err) => {
                setAuthDetails({
                    isLoading: false,
                    isLoggedIn: false,
                    user: null,
                });
                return null;
            });
    };

    // Signout function
    const Signout = async () => {
        setToken(null);
    };

    // Effect to handle token changes and fetch user profile
    useEffect(() => {
        if (!token) {
            setAuthToken(null);
            setAuthDetails({
                isLoading: false,
                isLoggedIn: false,
                user: null,
            });
        } else {
            setAuthToken(token);
            getUserProfile(token);
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ ...authDetails }}>
            <AuthActionContext.Provider value={{ Signin, Signout }}>
                {children}
            </AuthActionContext.Provider>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
