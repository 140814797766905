import React from "react";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./style.css";

const AboutUs = () => {
  return (
    <div className="modal_background">
      <div className="modal_container">
        <div className={"close"}>
          <Link to="login">
            Close
            <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
          </Link>
        </div>
        <div className="modal_content">
          <h3>About Us:</h3>
          <p>
          TIEDN is an internal CRM app managed by the Indian Express Group. This app is used by the various internal users which involves Depot Salesman, Circulation Sales Executive, Circulation Collection Executive and their Managers. This app helps in operating daily distribution and circulation of Newspapers & Magazines under the flagship of Indian Express Group. Along with the distribution and circulation, app also enables the users to track daily return, unsold and collection at various depot levels. Users will be able to update their attendance using the same module. This data stored in the app will be used by internal employees for daily tracking and analysis purpose.
          <br/>
          <br/>
          Read more about the Indian Express Group here:
          <br/> 
          <a href="http://expressgroup.indianexpress.com">http://expressgroup.indianexpress.com</a>
          </p>
          <div className="hide_modal_btn">
            {/* <button onClick={()=>{closeModal(false)}}>CLOSE</button> */}
            <Link to="login">To Login Page</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
