
import React, { useEffect, useState } from "react";
import {
    defaultColsDef,
    ROLE_VS_PERMISSIONS,
    ROLES,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import {
    getDefaultUnsoldList,
} from "../../../state/actions/dashboard.action";
import Button from "../../base/Button";
import PageHeader from "../../base/PageHeader";
import styles from "./dailySales.module.scss";
import {
    customDateTimeFormat,
    generateDropDownOption,
    subtractNDays,
} from "../../../utils";
import { AgGridReact } from "ag-grid-react";
import TableHeader from "../../base/TableHeader";
import { useAuthContext } from "../../../context/auth";
import Select from "../../base/Select";
import { axiosInstance } from "../../../state/axios";

const UnsoldReturnSummary = () => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.id;

    const [pageState, setPageState] = useFormState<{
        startDate: Date;
        selectedPublication: Array<any>,
        filtersApplied: boolean;
        publicationDropdownOption: Array<any>
    }>({
        startDate: new Date(),
        selectedPublication: ["2", "1", "3", "4", "5"],
        filtersApplied: false,
        publicationDropdownOption: []
    });

    const { exec, data } = useQuery();

    const TableColConfig = [
        ...(ROLES.includes(role) && (role === "Admin" || role === "West and South Head")
            ? [
                {
                    headerName: "Branch Name",
                    field: "Branch Name",
                    minWidth: 130,
                },
            ]
            : []),
        ...(ROLES.includes(role) && (
            role === "City Head" ||
            role === "Operation Head" || role === "Branch Head" || role === "Collection Backend Executive")
            ? [
                {
                    headerName: "Division",
                    field: "division",
                    minWidth: 130,
                    // rowSpan: rowSpan,
                    // cellClassRules: {
                    //     "show-cell": "value !== undefined",
                    // },
                }
            ]
            : [])
        ,
        {
            headerName: "Publication",
            field: "publicationName",
            minWidth: 130,
        },
        {
            headerName: "PO",
            field: "Po",
            minWidth: 80,
        },

        {
            headerName: "Trade Suppy",
            field: "tradeSupply",
            minWidth: 130,
        },
        {
            headerName: "Subscriptions",
            field: "subscriptions",
            minWidth: 130,
        },
        { headerName: "Complimentary Copy", field: "complementary", minWidth: 140, },
        { headerName: "Fresh Unsold", field: "unsold", minWidth: 130, },
        { headerName: "Returns", field: "Return", minWidth: 130, },
        { headerName: "Total Fresh Unsold + Return", field: "Total Fresh Unsold + Return", minWidth: 150, },
        { headerName: "NetPaid Sales", field: "netPaidSales", minWidth: 130, },
        {
            headerName: "Total Unsold %",
            field: "totalUnsoldPercent",
            minWidth: 100,
            valueGetter: ({ data }: any) => {
                return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
            }
        },


    ];

    const fetchListing = (withFilter: boolean = true) => {
        return exec(() => getDefaultUnsoldList(
            pageState.startDate,
            userID,
            pageState.selectedPublication
        )
        );
    }

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();

    };

    const getPublicationReportFields = () => {
        axiosInstance
            .get("/masters/publications")
            .then((res: any) => {
                setPageState({
                    ...pageState,
                    publicationDropdownOption: generateDropDownOption(res.data.data)
                });
            })
            .catch(function (error) { });
    };

    useEffect(() => {
        getPublicationReportFields();
    }, [])

    const handlePublicationChange = (value: any) => {
        setPageState({
            selectedPublication: value.selectedPublication
        });
    }

    useEffect(() => {
        fetchListing();
    }, [pageState.startDate, pageState.selectedPublication])
    return (
        <>

            <TableHeader
                header={"Summary"}
                rightSection={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: '10px'
                    }}>
                        <div className="filter_field_date">
                            <input
                                type={"date"}
                                name="startDate"
                                value={
                                    pageState.startDate
                                        .toISOString()
                                        .split("T")[0]
                                }
                                // max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                    setPageState({
                                        startDate: new Date(
                                            e.currentTarget.value
                                        ),
                                    });
                                }}
                            />
                        </div>
                        <div className="filter_field">
                            <Select
                                // label="Publication *"
                                className={styles.select}
                                multi={true}
                                name="selectedPublication"
                                selectedId={pageState.selectedPublication}
                                optionAccessor="label"
                                onChange={(value) => {
                                    handlePublicationChange(value)
                                }}

                                options={pageState.publicationDropdownOption}
                            />
                        </div>
                    </div>
                }
            />
            <div className="ag-theme-alpine ag_grid_table_wrapper_low">
                <AgGridReact
                    suppressRowTransform={true}
                    rowData={data}
                    columnDefs={TableColConfig}
                    defaultColDef={defaultColsDef}
                    onGridReady={onGridReady}
                />
            </div>
        </>
    );
};

export default UnsoldReturnSummary;
