import { useFormState } from "../../../hooks";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import styles from "./dashboardScreen.module.scss";
import DailySales from "../DailySales";
import CollectionPage from "../CollectionPage";
import PrintOrder from "../PrintOrder";
import PrintOrderSample from "../PrintOrderSample"
import UserAttandance from "../UserAttandance";
import { useAuthContext } from "../../../context/auth";
import { WEB_ROLES } from "../../../constants";

const DashboardScreen = () => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const [pageState, setPageState] = useFormState<{
        selectedView: string;
    }>({
        selectedView: "DAILY_SALES_REPORT",
    });

    return (
        <>
            <PageHeader
                header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "1rem" }}> Dashboard</div>
                        <Select
                            multi={false}
                            className={styles.select}
                            optionAccessor="label"
                            onChange={(valObj, id) =>
                                setPageState({ selectedView: id as string })
                            }
                            selectedId={pageState.selectedView}
                            options={
                                role !== WEB_ROLES["Dispatch Executive"] ? [
                                    {
                                        label: "Daily Sales report",
                                        id: "DAILY_SALES_REPORT",
                                    },
                                    { label: "Collections", id: "COLLECTION" },
                                    { label: "Attendance", id: "ATTENDANCE" },
                                    { label: "Print Order", id: "PRINT_ORDER" },
                                    { label: "Print Order Sampling", id: "PRINT_ORDER_SAMPLING" }] : [
                                    {
                                        label: "Daily Sales report",
                                        id: "DAILY_SALES_REPORT",
                                    },
                                    { label: "Collections", id: "COLLECTION" },
                                ]
                            }
                        />
                    </div>
                }
                breadCrumb={["Dashboard"]}
            />

            {pageState.selectedView === "DAILY_SALES_REPORT" &&
                <DailySales selectedView={pageState.selectedView} />
            }
            {pageState.selectedView === "COLLECTION" &&
                <CollectionPage selectedView={pageState.selectedView} />
            }
            {pageState.selectedView === "ATTENDANCE" &&
                <UserAttandance selectedView={pageState.selectedView} />
            }
            {pageState.selectedView === "PRINT_ORDER" &&
                <PrintOrder selectedView={pageState.selectedView} />
            }
            {pageState.selectedView === "PRINT_ORDER_SAMPLING" &&
                <PrintOrderSample selectedView={pageState.selectedView} />
            }

        </>
    );
};

export default DashboardScreen;
