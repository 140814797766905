import React, { FC } from "react";
import { isEmptyObject } from "../../../utils";
import Table, { ITableConfig } from "../../helper/Table/Table";
import { baseURL } from "../../../constants";
const couponConfig: ITableConfig[] = [
    {
        header: "Coupon No.",
        accessor: "coupon_no",
    },
    {
        header: "Status",
        accessor: "coupon_status",
    },
    {
        header: "Depot",
        accessor: "depot_name",
    },
    {
        header: "Vendor",
        accessor: "vendor_name",
    },
    {
        header: "Expiry Date",
        accessor: "exp_date",
    },
    {
        header: "Amount",
        accessor: "amount",
    },
    {
        header: "Bill Code",
        accessor: "bill_code",
    },
];
const checkConfig: ITableConfig[] = [
    // {
    //     header: "Micr Code",
    //     accessor: "micr_code",
    // },
    {
        header: "Cheque Number",
        accessor: "number",
    },
    {
        header: "Bank",
        accessor: "bank_name",
    },
    {
        header: "Issue date",
        accessor: "issue_date",
    },
    {
        header: "Image",
        accessor: "cheque_image",
        cellRenderer: (data) => {
            return <img style={{ width: '100px' }} src={`${baseURL}/print/download-file/img-file?fileurl=${data.cheque_image_url}`} />
        },
    },
];

const PaymentInfo: FC<{ data: any }> = ({ data }) => {

    return (
        <Table
            tableData={
                data.payment_mode === "coupon"
                    ? data.coupon_payment_dtos
                    : [data.cheque_payment_dto]
            }
            tableConfig={
                data.payment_mode === "coupon" ? couponConfig : checkConfig
            }
        />
    );
};

export default PaymentInfo;
