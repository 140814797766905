
import React, { useEffect, useState } from "react";
import {
    defaultColsDef,
    ROLES,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import { AgGridReact } from "ag-grid-react";
import TableHeader from "../../base/TableHeader";
import { useAuthContext } from "../../../context/auth";
import { getAttendanceSummaryList } from "../../../state/actions/attendance.action";

const AttendanceSummary = () => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.id;

    const [pageState, setPageState] = useFormState<{
        startDate: Date;
        filtersApplied: boolean;
    }>({
        startDate: new Date(),
        filtersApplied: false,
    });

    const { exec, data } = useQuery();


    const attendanceColsDefs = [
        ...(ROLES.includes(role) && (role === "Admin" || role === "West and South Head")
            ? [
                {
                    headerName: "Branch Name",
                    field: "city",
                },
                {
                    headerName: "No of City Head",
                    field: "cityHead"
                },
                {
                    headerName: "No of City Head Present",
                    field: "cityHeadPresent"
                },
                {
                    headerName: "No of City Head Absent",
                    field: "cityHeadAbsent"
                },
                {
                    headerName: "No of Regional Managers",
                    field: "regionalManagers"
                },
                {
                    headerName: "No of Regional Managers Present",
                    field: "regionalManagersPresent"
                },
                {
                    headerName: "No of Regional Managers Absent",
                    field: "regionalManagersAbsent"
                },
            ]
            : []),

        ...(ROLES.includes(role) && (role === "Operation Head" || role === "Branch Head" || role === "Collection Backend Executive")
            ? [
                {
                    headerName: "Regional Manager / City Head",
                    field: "executiveNames",
                },
            ]
            : []),
        ...(ROLES.includes(role) && (role === "City Head")
            ? [
                {
                    headerName: "Regional Manager",
                    field: "executiveNames",
                },
            ]
            : []),
        ...(ROLES.includes(role) && (role === "Regional Manager")
            ? [
                {
                    headerName: "Executive Name",
                    field: "executiveNames",
                },
            ]
            : []),


        ...(ROLES.includes(role) && (role === "Operation Head" || role === "Branch Head" || role === "Collection Backend Executive" ||
            role === "City Head" ||
            role === "Regional Manager")
            ? [
                {
                    headerName: "Depot/Parcel",
                    field: "depotName"
                },
                {
                    headerName: "Slot",
                    field: "slot",
                },
            ]
            : []),

    ];

    const [rowData, setRowData] = useState<any>([]);

    useEffect(() => {
        exec(() => getAttendanceSummaryList(pageState.startDate, userID));
    }, [pageState.startDate]);
    useEffect(() => {
        setRowData(data);
    }, [data]);

    return (
        <>
            <TableHeader
                header={"Summary"}
                rightSection={
                    <div className="filter_field_date">
                        <input
                            type={"date"}
                            name="startDate"
                            value={
                                pageState.startDate
                                    .toISOString()
                                    .split("T")[0]
                            }
                            // max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                                setPageState({
                                    startDate: new Date(
                                        e.currentTarget.value
                                    ),
                                });
                            }}
                        />
                    </div>
                }
            />
            <div className="ag-theme-alpine ag_grid_table_wrapper_low">
                <AgGridReact
                    rowData={rowData}
                    columnDefs={attendanceColsDefs
                    }
                    defaultColDef={defaultColsDef}
                />
            </div>
        </>
    );
};

export default AttendanceSummary;
