import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import Input from "../../base/Input";
import {
    addAttendanceRadius,
    getAttendanceRadius,
    updateAttendanceRadius,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Radius = () => {
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [formState, handleInputChange, resetState, setInitialState, dirty] = useFormState<any>({});
    const dataColumnDefs = [
        {
            headerName: "Radius",
            field: "radius",
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                            icon={faPen}
                            onClick={() => {
                                setShowModal(true);
                                handleInputChange({
                                    radius: data.radius,
                                    id: data.id,
                                    edit: true,
                                });
                            }}
                        />
                    </div>

                );
            },
        },
    ];
    useEffect(() => {
        exec(() => getAttendanceRadius());
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);

    return (
        <>
            <PageHeader
                header="Attendance Radius"
                breadCrumb={["Dashboard", "Master", "Attendance Radius"]}
                rightSection={
                    rowData && rowData.length < 1 &&
                    <button onClick={() => {
                        setShowModal(true);
                        handleInputChange({
                            radius: "",
                            id: "",
                            edit: false,
                        });
                    }}>Add</button>
                }
            />
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />

            <Modal
                header={<h3>{formState.edit ? "Update" : "Add"} Radius</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (
                            !formState.radius
                        ) {
                            alert("Please select required fields.");
                        } else {
                            if (formState.edit) {
                                const res = await updateAttendanceRadius(formState);
                                if (res !== "error") {
                                    exec(() => getAttendanceRadius());
                                    alert("Attendance Radius updated successfully.");
                                } else {
                                    alert(
                                        "An error occurred while processing request. Please try again"
                                    );
                                }
                                setShowModal(false);
                            } else {
                                const res = await addAttendanceRadius(formState);
                                if (res !== "error") {
                                    exec(() => getAttendanceRadius());
                                    alert("Attendance Radius added successfully.");
                                } else {
                                    alert(
                                        "An error occurred while processing request. Please try again"
                                    );
                                }
                                setShowModal(false);
                            }
                        }
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <Input
                            type="number"
                            required
                            label="Radius*"
                            style={{ flex: 1 }}
                            name="radius"
                            onChange={handleInputChange}
                            value={formState.radius}
                        />
                    </div>

                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit" disabled={!dirty}>
                            {formState.edit ? "Update" : "Add"}
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Radius;
