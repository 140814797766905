import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import Input from "../../base/Input";
import PageHeader from "../../base/PageHeader";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faCheckCircle,
    faPen,
    faTimesCircle,
    faPlusCircle,
    faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPrintOrder, updatePrintOrderStatus, updatePrintOrderTrade } from "../../../state/actions/print.action";
import { useAuthContext } from "../../../context/auth";
import { WEB_ROLES } from "../../../constants";
import PrintOrderSummary from "./summary";
import ConfirmationModal from "../../base/ConfirmationModal";
import "./printOrder.scss";
import { difference } from "lodash-es";
import { addNDays, convertToProperCase, subtractNDays } from "../../../utils";

const PrintOrder = ({ selectedView }: { selectedView: string }) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.login_id;
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [actionStatus, setActionStatus] = useState("");
    const [actionId, setActionId] = useState<string | number>("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [formState, handleInputChange, resetState, setInitialState, dirty] = useFormState<any>({});
    const [pageState, setPageState] = useFormState<{
        fromDate: Date;
        toDate: Date;
    }>({
        fromDate: subtractNDays(7),
        toDate: new Date(),
    });

    const isEditable = (requestStatus: string, role: string) => {
        if (requestStatus === "PENDING" && (role === "Regional Manager" || role === "City Head" || role === "Branch Head")) {
            return true
        }
        if (requestStatus === "APPROVED" && (role === "City Head" || role === "Branch Head")) {
            return true
        }
        if (requestStatus === "APPROVED-BY-CH" && role === "Branch Head") {
            return true
        }
        return false
    }
    const dataColumnDefs = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Executive Name",
            field: "executive_name",
        },
        {
            headerName: "Type",
            field: "date_type",
            valueGetter: ({ data }: any) => {
                return convertToProperCase(data.date_type)
            },
        },
        {
            headerName: "From Date",
            field: "to_show_formatted_from_date",
        },
        {
            headerName: "To Date",
            field: "to_show_formatted_to_date",
        },
        {
            headerName: "Posting Slot",
            field: "posting_slot",
            valueGetter: ({ data }: any) => {
                return convertToProperCase(data.posting_slot)
            },
        },
        {
            headerName: "Status",
            field: "status",
            valueGetter: ({ data }: any) => {
                if (data.status === "FINALAPPROVED") {
                    return "Approved-BH"
                }
                if (data.status === "APPROVED-BY-CH") {
                    return "Approved-CH"
                }
                return convertToProperCase(data.status)
            },
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                if (isEditable(data.status, role)) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPen}
                                size="2x"
                                style={{
                                    color: "grey",
                                    cursor: "pointer",
                                }}
                                title={"Edit"}
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedRow(data)
                                    handleInputChange({ publications: [...data.publications] })
                                }}
                            />
                            <FontAwesomeIcon
                                onClick={() => handleAction(
                                    data.print_order_id,
                                    role === "Regional Manager" ? "APPROVED" : role === "City Head" ? "APPROVED-BY-CH" : "FINALAPPROVED"
                                )}
                                size="2x"
                                style={{
                                    color: "green",
                                    cursor: "pointer",
                                }}
                                title={role === "Regional Manager" ? "APPROVED" : role === "City Head" ? "APPROVED-BY-CH" : "FINALAPPROVED"}
                                icon={faCheckCircle}
                            />
                            <FontAwesomeIcon
                                onClick={() => handleAction(
                                    data.print_order_id,
                                    "REJECTED"
                                )}
                                size="2x"
                                style={{
                                    color: "red",
                                    cursor: "pointer",
                                }}
                                title={"Reject"}
                                icon={faTimesCircle}
                            />
                        </div>
                    );
                }
            },
        },
    ];
    useEffect(() => {
        exec(() => getPrintOrder(userID, pageState.fromDate.toISOString().split("T")[0], pageState.toDate.toISOString().split("T")[0]));
    }, []);

    const handleSearch = () => {
        exec(() => getPrintOrder(userID, pageState.fromDate.toISOString().split("T")[0], pageState.toDate.toISOString().split("T")[0]));
    };
    useEffect(() => {
        setRowData(data);
    }, [data]);



    const handleAction = (id: string | number, status: string,) => {
        if (status === "REJECTED") {
            setActionId(id)
            setActionStatus(status)
            setShowConfirmationModal(true)
        } else {
            handleConfirmation(id, status)
        }
    };

    const handleConfirmation = (id: string | number, status: string,) => {
        exec(() =>
            updatePrintOrderStatus({
                user_id: userID,
                status: status,
                print_order_id: id,
                remarks: remarks,
            }).then(() => {
                setShowConfirmationModal(false)

                exec(() => getPrintOrder(userID, pageState.fromDate.toISOString().split("T")[0], pageState.toDate.toISOString().split("T")[0]));
            })
        );

    };

    const handleChange = (value: any, id: number) => {
        let newmapee = formState.publications.map((pub: any) => {
            return {
                ...pub,
                updated_value: id === pub.id ? Number(value.updated_value) : Number(pub.updated_value)
            }

        })
        handleInputChange({ publications: [...newmapee] })
    }

    const handleQtyAction = (id: string | number, type: string,) => {
        if (type === "ADD") {
            let newmapee = formState.publications.map((pub: any) => {
                return {
                    ...pub,
                    updated_value: id === pub.id ? Number(pub.updated_value) + 1 : Number(pub.updated_value),
                    difference: id === pub.id ? Number(pub.difference) + 1 : Number(pub.difference)
                }
            })
            handleInputChange({ publications: [...newmapee] })

        } else {
            let newmapee = formState.publications.map((pub: any) => {
                return {
                    ...pub,
                    updated_value: id === pub.id ? Number(pub.updated_value) - 1 : Number(pub.updated_value),
                    difference: id === pub.id ? Number(pub.difference) - 1 : Number(pub.difference)
                }
            })
            handleInputChange({ publications: [...newmapee] })
        }

    };

    return (
        <>
            {/* <PageHeader
                header="Print Order"
                breadCrumb={["Dashboard", "Print Order"]}
            /> */}
            <PrintOrderSummary selectedView={selectedView}/>
            <div className="table_filter_wrapper" style={{
                marginTop: '1rem'
            }}>
                <div className="table_filter table_filter_columns_4">
                    <div className="filter_field">
                        <label className="filter_field_label">Range</label>
                        <div className="filter_field_date filter_field_date_range">
                            <input
                                type={"date"}
                                name="startDate"
                                value={
                                    pageState.fromDate
                                        .toISOString()
                                        .split("T")[0]
                                }
                                max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                    setPageState({
                                        fromDate: new Date(
                                            e.currentTarget.value
                                        ),
                                    });
                                }}
                            />
                            <input
                                type={"date"}
                                name="endDate"
                                value={
                                    pageState.toDate.toISOString().split("T")[0]
                                }
                                // max={new Date().toISOString().split("T")[0]}
                                min={
                                    addNDays(0, pageState.fromDate)
                                        .toISOString()
                                        .split("T")[0]
                                }
                                onChange={(e) => {
                                    setPageState({
                                        toDate: new Date(e.currentTarget.value),
                                    });
                                }}
                            />
                        </div>
                    </div>

                </div>

                <div className="table_filter_button">
                    <Button onClick={handleSearch}>Search</Button>
                </div>
            </div>
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />

            <Modal
                header={<h3>Update PO</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        // if (
                        //     !formState.check_in_latitude ||
                        //     !formState.check_in_longitude ||
                        //     !formState.depot_address
                        // ) {
                        //     alert("Please select required fields.");
                        // } else {
                        const payload = {
                            ...formState,
                            user_id: userID,
                            print_order_id: selectedRow?.print_order_id,
                            status: selectedRow?.status
                        }
                        const res = await updatePrintOrderTrade(payload);
                        if (res !== "error") {
                            exec(() => getPrintOrder(userID, pageState.fromDate.toISOString().split("T")[0], pageState.toDate.toISOString().split("T")[0]));
                            alert("Print Order updated successfully.");
                        } else {
                            alert(
                                "An error occurred while processing request. Please try again"
                            );
                        }
                        setShowModal(false);
                        // }
                    }}
                >
                    {formState?.publications?.filter((pub: any) => pub.trade > 0).map((el: any, index: number) => {
                        return <div key={`${el.trade_name}-${el.id}`} className="update-po-input-wrapper">
                            <label>{`${el.trade_name} (${el.trade})`}</label>
                            <div className="update-po-input">
                                <FontAwesomeIcon
                                    onClick={() => handleQtyAction(el.id, "REMOVE")}
                                    style={{
                                        color: "red",
                                        cursor: "pointer",
                                    }}
                                    icon={faMinusCircle}
                                />
                                <input
                                    type="number"
                                    name="updated_value"
                                    disabled
                                    value={el.updated_value}
                                />
                                <FontAwesomeIcon
                                    onClick={() => handleQtyAction(el.id, "ADD")}
                                    style={{
                                        color: "red",
                                        cursor: "pointer",
                                    }}
                                    icon={faPlusCircle}
                                />
                                <input
                                    type="number"
                                    name="difference"
                                    disabled
                                    value={el.difference}
                                />
                            </div>
                        </div>
                    })}

                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit">
                            Update
                        </Button>
                    </div>
                </form>
            </Modal>
            <ConfirmationModal
                header={"Remarks"}
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <div style={{ display: "flex", flexFlow: "column wrap" }}>
                    <input
                        type={"text"}
                        name="remarks"
                        onChange={(e) => {
                            setRemarks(e.currentTarget.value,
                            );
                        }}
                        placeholder="Please enter remarks"
                        style={{
                            height: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '4px 10px',
                        }}
                    />
                    <p style={{ margin: 0, marginTop: '.5rem' }}>You are about to perform an action that cannot be undone. Please confirm if you want to proceed.</p>
                    <div style={{ display: "flex", columnGap: "8px", justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button className="default" type="button" onClick={() => setShowConfirmationModal(false)}>
                            No
                        </Button>
                        <Button type="button" onClick={() => handleConfirmation(actionId, actionStatus)}>
                            Yes
                        </Button>
                    </div>
                </div>

            </ConfirmationModal>
        </>
    );
};

export default PrintOrder;
