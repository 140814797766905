import { Outlet } from "react-router-dom";
import TopBar from "../../base/TopBar";
import SideNav from "../../widgets/SideNav";
import styles from "./layout.module.scss";

const Dashboard = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left_bar}>
                <SideNav />
            </div>
            <div className={styles.right_bar}>
                <div className={styles.right_bar_content}>
                    <TopBar />
                    <div className={styles.data_bar_content}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
