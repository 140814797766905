
import React, { useEffect, useState } from "react";
import {
    defaultColsDef,
    ROLES,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import { AgGridReact } from "ag-grid-react";
import TableHeader from "../../base/TableHeader";
import { useAuthContext } from "../../../context/auth";
import { getPrintOrderSummary } from "../../../state/actions/print.action";

const PrintOrderSummary = ({ selectedView }: { selectedView: string }) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.login_id;
    const [columnDef1, setColumnDef1] = useState<any>([]);
    const [pageState, setPageState] = useFormState<{
        startDate: Date;
        filtersApplied: boolean;
    }>({
        startDate: new Date(),
        filtersApplied: false,
    });

    const { exec, data } = useQuery();

    const [rowData, setRowData] = useState<any>([]);
    useEffect(() => {
        exec(() => getPrintOrderSummary(pageState.startDate, userID));
    }, [pageState.startDate]);
    useEffect(() => {
        if (data !== null && data.length > 0) {
            const tableData = data.map((item: any) => {
                const division = Object.keys(item)[0];
                const newspapers = item[division].reduce((acc: any, paper: any) => {
                    const paperName = Object.keys(paper)[0];
                    acc[paperName] = paper[paperName];
                    return acc;
                }, {});
                if (ROLES.includes(role) && role === "Admin") {
                    return { "Branch": division, ...newspapers };
                } else {
                    return { "Division": division, ...newspapers };
                }
            });
            const filteredData = tableData.filter((item: any) => {
                const keys = Object.keys(item)
                return keys.length > 1 && item.Division !== "Difference"
            });
            if (filteredData.length > 0) {
                const headers = Object.keys(filteredData[0]);
                const formattedHeaders = headers.map(header => {
                    return {
                        headerName: header,
                        field: header,
                        valueGetter: (props: any) => {
                            const value = props.data[header];
                            return value !== undefined && value !== null ? value : '-';

                        },
                    }
                });
                setColumnDef1(formattedHeaders)
                setRowData(tableData);
            } else {
                setColumnDef1([
                    {
                        headerName: ROLES.includes(role) && role === "Admin" ? "Branch" : "Division",
                        field: "",
                    },
                    {
                        headerName: "Indian Express",
                        field: "",
                    },
                    {
                        headerName: "Loksatta",
                        field: "",
                    },
                    {
                        headerName: "Financial Express",
                        field: "",
                    },
                ]);
                setRowData([])
            }
        } else {
            setColumnDef1([
                {
                    headerName: ROLES.includes(role) && role === "Admin" ? "Branch" : "Division",
                    field: "",
                },
                {
                    headerName: "Indian Express",
                    field: "",
                },
                {
                    headerName: "Loksatta",
                    field: "",
                },
                {
                    headerName: "Financial Express",
                    field: "",
                },
            ]);
            setRowData([])
        }
    }, [data]);


    return (
        <>
            <TableHeader
                header={"Summary"}
                rightSection={
                    <div className="filter_field_date">
                        <input
                            type={"date"}
                            name="startDate"
                            value={
                                pageState.startDate
                                    .toISOString()
                                    .split("T")[0]
                            }
                            // max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                                setPageState({
                                    startDate: new Date(
                                        e.currentTarget.value
                                    ),
                                });
                            }}
                        />
                    </div>
                }
            />
            <div className={`ag-theme-alpine ag_grid_table_wrapper_low summary_${selectedView.toLocaleLowerCase()}`}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDef1}
                    defaultColDef={defaultColsDef}
                />
            </div>
        </>
    );
};

export default PrintOrderSummary;
