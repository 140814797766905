
import { useEffect, useState } from "react";
import {
    ATTENDANCE_FLAG,
    REPORT_TYPE,
    ROLE_VS_PERMISSIONS,
    TYPE,
    SUB_EMPLOYEE_USER_ROLES,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import { addNDays, convertMilliseconds, convertToProperCase, customDateFormat, customDateTimeFormat, customTimeFormat, generateDropDownOption, subtractNDays } from "../../../utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "../../base/Button";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import "./reportsPage.css";
import { axiosInstance } from "../../../state/axios";
import AgGridWrapper from "../../base/AgGridWrapper";
import { useAuthContext } from "../../../context/auth";

interface Trade {
    [key: string]: number | string; // Dynamic keys with values being either numbers (for trade fields) or strings (for publication)
    publication: string; // Ensure publication key is explicitly typed as a string
}
interface RowData {
    [key: string]: any; // Dynamic keys for RowData fields (use `any` to support various value types)
    trade?: Trade[]; // Optional trade array
}
const ReportsPage = () => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const { loading, exec, data } = useQuery();
    const { exec: circulationExec, loading: circulationExecLoading } =
        useQuery();
    const { exec: cityHead, loading: cityHeadLoading } =
        useQuery();
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [showSnack, setShowSnack] = useState(false);
    const [pageState, setPageState] = useFormState<{
        selectedReportType: string;
        start_date: string;
        end_date: string;
        selectedRegion: Array<any>;
        selectedBranch: Array<string>;
        selectedCenter: string;
        selectedDepot: string;
        publicationDropdownOption: Array<any>;
        circulationExecutiveDropdownOption: Array<any>;
        cityHeadDropdownOption: Array<any>;
        collectionExecutiveDropdownOption: Array<any>;
        parcelDepotDropdownOption: Array<any>;
        regionDropdownOption: Array<any>;
        filtersApplied: boolean;
        selectedParcelDepot: Array<string>;
        selectedEmployeeType: string;
        selectedPublication?: Array<string>;
        selectedAttendance: string;
        selectedCouponStatus: Array<string>;
        selectedPoStatus: Array<string>;
        selectedPaymentMode: Array<string>;
        selectedPostingSlot: Array<string>;
        selectedType: Array<string>;
        selectedCirculationExecutive: Array<string>;
        selectedCityHead: Array<string>;
        selectedCollectionExecutive: string;
    }>({
        selectedReportType: "",
        start_date: subtractNDays(7).toISOString().split("T")[0],
        end_date: new Date().toISOString().split("T")[0],
        selectedRegion: [],
        selectedBranch: [],
        selectedCenter: "",
        selectedDepot: "",
        publicationDropdownOption: [],
        circulationExecutiveDropdownOption: [],
        cityHeadDropdownOption: [],
        collectionExecutiveDropdownOption: [],
        parcelDepotDropdownOption: [],
        regionDropdownOption: generateDropDownOption(user.region_details, "description"),
        filtersApplied: false,
        selectedParcelDepot: [],
        selectedEmployeeType: "",
        selectedPublication: [],
        selectedAttendance: "",
        selectedCouponStatus: [],
        selectedPoStatus: [],
        selectedPaymentMode: [],
        selectedPostingSlot: [],
        selectedType: [],
        selectedCirculationExecutive: [],
        selectedCityHead: [],
        selectedCollectionExecutive: "",
    });
    const getColumnDefs = () => {
        switch (pageState.selectedReportType) {
            case "BRANCH_WISE_DAILY_SALES_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "createdAt",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.createdAt)
                        },
                    },
                    // {
                    //     headerName: "Division",
                    //     field: "division",
                    //     minWidth: 100,
                    // },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "tradeSupply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Fresh Unsold",
                        field: "unsold",
                        minWidth: 100,
                    },
                    {
                        headerName: "Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Fresh Unsold + Return",
                        field: "Total Fresh Unsold + Return",
                        minWidth: 180,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "REGION_WISE_DAILY_SALES_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "createdAt",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.createdAt)
                        },
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: 100,
                    },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },
                    // {
                    //     headerName: "Depot/Parcel",
                    //     field: "name",
                    //     minWidth: 130,
                    // },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "tradeSupply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Fresh Unsold",
                        field: "unsold",
                        minWidth: 100,
                    },
                    {
                        headerName: "Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Fresh Unsold + Return",
                        field: "Total Fresh Unsold + Return",
                        minWidth: 180,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "EXECUTIVE_WISE_DAILY_SALES_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "createdAt",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.createdAt)
                        },
                    },
                    {
                        headerName: "Executive Name",
                        field: "circulationExecutiveName",
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: 100,
                    },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },
                    // {
                    //     headerName: "Parcel/Depot",
                    //     field: "parcelDepotName",
                    //     minWidth: 120,
                    //     valueGetter: ({ data }: any) => {
                    //         return data.parcelDepotName !== undefined ? data.parcelDepotName : "Parcel"
                    //     },
                    // },
                    // {
                    //     headerName: "Ship to Party Code",
                    //     field: "ship_to_code",
                    //     minWidth: 120,
                    // },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "tradeSupply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Fresh Unsold",
                        field: "unsold",
                        minWidth: 100,
                    },
                    {
                        headerName: "Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Fresh Unsold + Return",
                        field: "Total Fresh Unsold + Return",
                        minWidth: 180,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "DEPOT_WISE_DAILY_SALES_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "createdAt",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.createdAt)
                        },
                    },
                    {
                        headerName: "Depot/Parcel",
                        field: "name",
                        minWidth: 130,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ship_to_code",
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: 100,
                    },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },

                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "tradeSupply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Fresh Unsold",
                        field: "unsold",
                        minWidth: 100,
                    },
                    {
                        headerName: "Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Fresh Unsold + Return",
                        field: "Total Fresh Unsold + Return",
                        minWidth: 180,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "createdAt",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.createdAt)
                        },
                    },
                    {
                        headerName: "Executive Name",
                        field: "circulationExecutiveName",
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: 100,
                    },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },
                    {
                        headerName: "Ship to Party Name",
                        field: "parcelDepotName",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return data.parcelDepotName !== undefined ? data.parcelDepotName : "Parcel"
                        },
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ship_to_code",
                        minWidth: 120,
                    },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "tradeSupply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Fresh Unsold",
                        field: "unsold",
                        minWidth: 100,
                    },
                    {
                        headerName: "Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Fresh Unsold + Return",
                        field: "Total Fresh Unsold + Return",
                        minWidth: 180,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Fresh Unsold + Return"] / data["tradeSupply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "CIRCULATION_EXE_WISE_SUPPLY":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "Date",
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.Date)
                        },
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Executive name",
                        field: "Circulation Executive Name",
                        minWidth: 130,
                    },
                    {
                        headerName: "Publication",
                        field: "Publication_Name",
                        minWidth: 120,
                    },
                    {
                        headerName: "Depot/Parcel",
                        field: "Depot / Parcel",
                        minWidth: 130,
                    },
                    {
                        headerName: "Depot/Parcel name",
                        field: "Depot / Parcel Name",
                        minWidth: 130,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ShipToParty",
                        minWidth: 120,
                    },
                    {
                        headerName: "Bill to Party Code",
                        field: "BilltoParty",
                        minWidth: 120,
                    },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "Supply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "Subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "Complementary",
                        minWidth: 150,
                    },
                ]
            case "PHYSICAL_RETURN":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "Date",
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.Date)
                        },
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Executive name",
                        field: "Circulation Executive Name",
                        minWidth: 130,
                    },
                    {
                        headerName: "Publication",
                        field: "Publication",
                        minWidth: 120,
                    },
                    {
                        headerName: "Depot/Parcel",
                        field: "Depot / Parcel Point",
                        minWidth: 130,
                    },
                    {
                        headerName: "Depot/Parcel name",
                        field: "Depot / Parcel Name",
                        minWidth: 130,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ShipToParty",
                        minWidth: 120,
                    },
                    {
                        headerName: "Bill to Party Code",
                        field: "BilltoParty",
                        minWidth: 120,
                    },
                    {
                        headerName: "PO",
                        field: "Po",
                        minWidth: 90,
                    },
                    {
                        headerName: "Trade Supply",
                        field: "Supply",
                        minWidth: 100,
                    },
                    {
                        headerName: "Subscription",
                        field: "Subscriptions",
                        minWidth: 120,
                    },
                    {
                        headerName: "Complimentary Copy",
                        field: "Complementary",
                        minWidth: 150,
                    },
                    {
                        headerName: "Physical Return",
                        field: "Return",
                        minWidth: 100,
                    },
                    {
                        headerName: "NetPaid Sales",
                        field: "netPaidSales",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Unsold %",
                        field: "totalUnsoldPercent",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return `${(data["Total Return + Unsold"] / data["Supply"] * 100).toFixed(2)} %`
                        }
                    },
                ]
            case "ATTENDANCE_REPORT":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "date",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.date)
                        },
                    },
                    ...(pageState.selectedEmployeeType !== "Regional Manager"
                        ? [

                            {
                                headerName: "Executive Name",
                                field: "executiveName",
                                minWidth: 100,
                            },

                        ]
                        : [{
                            headerName: "Regional Manager",
                            field: "regionalManagerName",
                            minWidth: 150,
                        }]),

                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: 100,
                    },
                    {
                        headerName: "Depot Name",
                        field: "depotName",
                        minWidth: 150,
                    },
                    {
                        headerName: "Slot",
                        field: "slot",
                        minWidth: 100,
                    },
                    {
                        headerName: "Check in time",
                        field: "check_in",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customTimeFormat(data.check_in)
                        },
                    },
                    {
                        headerName: "Check out time",
                        field: "check_out",
                        minWidth: 130,
                        valueGetter: ({ data }: any) => {
                            return customTimeFormat(data.check_out)
                        },
                    },
                    {
                        headerName: "Duration",
                        field: "duration",
                        minWidth: 100,
                    },
                    {
                        headerName: "Attendance",
                        field: "attendance",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return data?.attendance === "Present" ? "Present" : "Absent"
                        }
                    },
                ]
            case "REGION_WISE_COLLECTION":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return isNaN(data.AcceptedCoupon + data.RejectedCoupon)
                                ? ""
                                : `${data.AcceptedCoupon + data.RejectedCoupon}`;
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                    },
                ]
            case "CITYHEAD_WISE_COLLECTION":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return isNaN(data.AcceptedCoupon + data.RejectedCoupon)
                                ? ""
                                : `${data.AcceptedCoupon + data.RejectedCoupon}`;
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                    },
                ]
            case "EXECUTIVE_WISE_COLLECTION":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Collection Executive",
                        field: "ExecutiveName",
                        minWidth: 150,
                    },
                    {
                        headerName: "Division",
                        field: "RegionName",
                        minWidth: 100,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return isNaN(data.AcceptedCoupon + data.RejectedCoupon)
                                ? ""
                                : `${data.AcceptedCoupon + data.RejectedCoupon}`;
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                    },
                ]
            case "DEPOT_WISE_COLLECTION":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Depot Name",
                        field: "Depot/Parcel Point",
                        minWidth: 130
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100
                    },
                    {
                        headerName: "Executive Name",
                        field: "CirculationName",
                        minWidth: 130,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return isNaN(data.AcceptedCoupon + data.RejectedCoupon)
                                ? 0
                                : `${data.AcceptedCoupon + data.RejectedCoupon}`;
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return data?.AcceptedCouponAmount !== undefined
                                ? data?.AcceptedCouponAmount
                                : 0;
                        },
                    },
                ]
            case "PARCEL_WISE_COLLECTION":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Parcel Name",
                        field: "Depot/Parcel Point",
                        minWidth: 150,
                    },
                    {
                        headerName: "Link to Depot",
                        field: "linkToDepot",
                        minWidth: 150,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ShipCode",
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Executive Name",
                        field: "circulationName",
                        minWidth: 130,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return data.AcceptedCoupon + data.RejectedCoupon
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return data.AcceptedCouponAmount || 0
                        },
                    },
                ]
            case "PAYMENT_OPTION_WISE":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Date",
                        field: "CreateDate",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.CreateDate)
                        },
                    },
                    {
                        headerName: "Parcel/Depot",
                        field: "Depot/Parcel Point",
                        minWidth: 150,
                    },
                    {
                        headerName: "Link to Depot",
                        field: "linkToDepot",
                        minWidth: 150,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "shipCode",
                        minWidth: 120,
                    },
                    {
                        headerName: "Division",
                        field: "Region",
                        minWidth: 100,
                    },
                    {
                        headerName: "Collection Executive Name",
                        field: "CirculationExec",
                        minWidth: 150,
                    },
                    {
                        headerName: "Security Deposit",
                        field: "SecurityDeposit",
                        minWidth: 120,
                    },
                    {
                        headerName: "Total Outstanding",
                        field: "TotalO/s",
                        minWidth: 150,
                    },
                    {
                        headerName: "Todays Invoice",
                        field: "TodayInvoice",
                        minWidth: 120,
                    },
                    {
                        headerName: "Payment Received",
                        field: "TodaysCollection",
                        minWidth: 100,
                    },
                    {
                        headerName: "Payment Mode",
                        field: "payment_Mode",
                        valueGetter: ({ data }: any) => {
                            return convertToProperCase(data.payment_Mode)
                        },
                        minWidth: 100,
                    },
                    {
                        headerName: "Approval Coupon",
                        field: "AcceptedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Rejected Coupon",
                        field: "RejectedCoupon",
                        minWidth: 100,
                    },
                    {
                        headerName: "Total Coupon",
                        field: "totalCoupon",
                        minWidth: 100,
                        valueGetter: ({ data }: any) => {
                            return isNaN(data.AcceptedCoupon + data.RejectedCoupon)
                                ? ""
                                : `${data.AcceptedCoupon + data.RejectedCoupon}`;
                        },
                    },
                    {
                        headerName: "Coupon Value",
                        field: "AcceptedCouponAmount",
                        minWidth: 100,
                    },
                ]
            case "COUPON_STATUS_WISE":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",
                    },
                    {
                        headerName: "Coupon Scan Date",
                        field: "created_on",
                        minWidth: 120,
                        valueGetter: ({ data }: any) => {
                            return customDateFormat(data.created_on)
                        },
                    },
                    {
                        headerName: "Redeeming Vendor",
                        field: "Redeeming Vendor",
                        minWidth: 150,
                    },
                    {
                        headerName: "Vendor Name (System)",
                        field: "vendor_name",
                        minWidth: 150,
                    },
                    {
                        headerName: "Linked to Depot",
                        field: "depot_name",
                        minWidth: 100,
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "shipCode",
                        minWidth: 120,
                    },
                    {
                        headerName: "Coupon Number",
                        field: "coupon_no",
                        minWidth: 120,
                    },
                    {
                        headerName: "Coupon Month",
                        field: "exp_date",
                        minWidth: 100,
                    },
                    {
                        headerName: "Coupon Amount",
                        field: "amount",
                        minWidth: 100,
                    },
                    {
                        headerName: "Publication",
                        field: "publication",
                        minWidth: 120,
                    },
                    {
                        headerName: "Coupon Status",
                        field: "coupon_status",
                        minWidth: 100,
                    },
                    {
                        headerName: "Remarks",
                        field: "remarks",
                        minWidth: 100,
                    },
                ]
            case "PRINT_ORDER":
            case "CITYHEAD_WISE_PRINT_ORDER": {
                if (!rowData || rowData.length === 0) {
                    return [];
                }

                const rowDataFirst = rowData[0];
                const tradeData = rowDataFirst.trade;
                let tradeColumns: Array<any> = [];
                if (tradeData && Array.isArray(tradeData)) {
                    tradeColumns = tradeData?.map((tradeObj: any) => {
                        return Object.keys(tradeObj).filter(key => key !== 'publication')
                            .map((fieldKey) => {

                                return {
                                    headerName: fieldKey.includes("req") ? `${tradeObj.publication} - Copy Revision` : `Trade Supply ${tradeObj.publication}`,
                                    field: fieldKey,
                                    valueGetter: ({ data }: any) => {
                                        if (!fieldKey.includes("req")) {
                                            return data[fieldKey];

                                        }
                                        return data[fieldKey] - data[`${fieldKey.split("_req")[0]}`]
                                    },
                                    minWidth: "100"
                                };
                            });
                    }).flat();
                }
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: (params: any) => params.node.rowIndex + 1,

                    },
                    {
                        headerName: "Publication Date",
                        field: "publication_date",
                        valueGetter: ({ data }: any) => customDateFormat(data.publication_date),
                        minWidth: "120"
                    },
                    {
                        headerName: "Type",
                        field: "date_type",
                        valueGetter: ({ data }: any) => {
                            return convertToProperCase(data.date_type)
                        },
                        minWidth: "120"
                    },
                    {
                        headerName: "Revision Date",
                        field: "from_date",
                        valueGetter: ({ data }: any) => {
                            return `${customDateFormat(data.from_date)}${data.to_date !== undefined ? `- ${customDateFormat(data.to_date)}` : ""}`
                        },
                        minWidth: "120"
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: "100"
                    },
                    {
                        headerName: "Category",
                        field: "category",
                        minWidth: "150"
                    },
                    {
                        headerName: "Depot/Parcel Name",
                        field: "type",
                        minWidth: "150"
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ship_to_code",
                        minWidth: "120"
                    },
                    ...tradeColumns, // Insert dynamically generated trade columns
                    {
                        headerName: "Posting Slot",
                        field: "revision_time",
                        valueGetter: ({ data }: any) => {
                            return convertToProperCase(data.revision_time)
                        },
                        minWidth: "100"
                    },
                    {
                        headerName: "Status",
                        field: "status",
                        valueGetter: ({ data }: any) => {
                            if (data.status === "FINALAPPROVED") {
                                return "Approved-BH"
                            }
                            if (data.status === "APPROVED-BY-CH") {
                                return "Approved-CH"
                            }
                            return convertToProperCase(data.status)
                        },
                        minWidth: "130"
                    },
                ];
            }
            case "CIRCULATION_WISE_PRINT_ORDER": {
                if (!rowData || rowData.length === 0) {
                    return [];
                }

                const rowDataFirst = rowData[0];
                const tradeData = rowDataFirst.trade;
                let tradeColumns: Array<any> = [];
                if (tradeData && Array.isArray(tradeData)) {
                    tradeColumns = tradeData?.map((tradeObj: any) => {
                        return Object.keys(tradeObj).filter(key => key !== 'publication')
                            .map((fieldKey) => {

                                return {
                                    headerName: fieldKey.includes("req") ? `${tradeObj.publication} - Copy Revision` : `Trade Supply ${tradeObj.publication}`,
                                    field: fieldKey,
                                    valueGetter: ({ data }: any) => {
                                        if (!fieldKey.includes("req")) {
                                            return data[fieldKey];

                                        }
                                        return data[fieldKey] - data[`${fieldKey.split("_req")[0]}`]
                                    },
                                    minWidth: "100"
                                };
                            });
                        // }

                        // return []



                    }).flat();
                }
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: (params: any) => params.node.rowIndex + 1,
                    },
                    {
                        headerName: "Publication Date",
                        field: "publication_date",
                        valueGetter: ({ data }: any) => customDateFormat(data.publication_date),
                        minWidth: "120"
                    },
                    {
                        headerName: "Type",
                        field: "date_type",
                        valueGetter: ({ data }: any) => {
                            return convertToProperCase(data.date_type)
                        },
                        minWidth: "120"
                    },
                    {
                        headerName: "Revision Date",
                        field: "from_date",
                        valueGetter: ({ data }: any) => {
                            return `${customDateFormat(data.from_date)}${data.to_date !== undefined ? `- ${customDateFormat(data.to_date)}` : ""}`
                        },
                        minWidth: "120"
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: "100"
                    },
                    {
                        headerName: "Executive Name",
                        field: "executive_name",
                        minWidth: "150"
                    },
                    {
                        headerName: "Category",
                        field: "category",
                        minWidth: "150"
                    },
                    {
                        headerName: "Depot/Parcel Name",
                        field: "type",
                        minWidth: "150"
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ship_to_code",
                        minWidth: "120"
                    },
                    ...tradeColumns, // Insert dynamically generated trade columns
                    {
                        headerName: "Posting Slot",
                        field: "revision_time",
                        valueGetter: ({ data }: any) => {
                            return convertToProperCase(data.revision_time)
                        },
                        minWidth: "100"
                    },
                    {
                        headerName: "Status",
                        field: "status",
                        valueGetter: ({ data }: any) => {
                            if (data.status === "FINALAPPROVED") {
                                return "Approved-BH"
                            }
                            if (data.status === "APPROVED-BY-CH") {
                                return "Approved-CH"
                            }
                            return convertToProperCase(data.status)
                        },
                        minWidth: "130"
                    },
                ];
            }
            case "SAMPLE_COPY":
                return [
                    {
                        headerName: "Sr No",
                        field: "srNo",
                        minWidth: 75,
                        valueGetter: "node.rowIndex + 1",

                    },
                    {
                        headerName: "Publication Date",
                        field: "publication_date",
                        valueGetter: ({ data }: any) => customDateFormat(data.publication_date),
                        minWidth: "120"
                    },
                    {
                        headerName: "Sample Date",
                        field: "from_date",
                        valueGetter: ({ data }: any) => {
                            return `${customDateFormat(data.from_date)}${data.to_date !== undefined ? `- ${customDateFormat(data.to_date)}` : ""}`
                        },
                        minWidth: "120"
                    },
                    {
                        headerName: "Division",
                        field: "division",
                        minWidth: "100"
                    },
                    {
                        headerName: "Category",
                        field: "category",
                        minWidth: "150"
                    },
                    {
                        headerName: "Depot/Parcel Name",
                        field: "executive_name",
                        minWidth: "150"
                    },
                    {
                        headerName: "Ship to Party Code",
                        field: "ship_to_code",
                        minWidth: "120"
                    },
                    // ...tradeColumns, // Insert dynamically generated trade columns
                    {
                        headerName: "Sample Copy - LS",
                        field: "ls_trade_req",
                        minWidth: "120"
                    },
                    {
                        headerName: "Sample Copy - IE",
                        field: "ie_trade_req",
                        minWidth: "120"
                    },
                    {
                        headerName: "Sample Copy - FE",
                        field: "fe_trade_req",
                        minWidth: "120"
                    },
                    {
                        headerName: "Sample Copy - JS",
                        field: "js_trade_req",
                        minWidth: "120"
                    },
                    {
                        headerName: "Sample Copy - LP",
                        field: "lp_trade_req",
                        minWidth: "120"
                    },
                    {
                        headerName: "Status",
                        field: "status",
                        valueGetter: ({ data }: any) => {
                            if (data.status === "FINALAPPROVED") {
                                return "Approved-BH"
                            }
                            if (data.status === "APPROVED-BY-CH") {
                                return "Approved-CH"
                            }
                            return convertToProperCase(data.status)
                        },
                        minWidth: "130"
                    },
                ];
            default:
                return [];
        }

    }

    // FORM INPUT CHANGE EVENT
    const handleInputChange = (data: any) => {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                setPageState({ ...pageState, [key]: data[key] });
            }
        }
        // setRowData([]);
    };

    const getPublicationReportFields = () => {
        axiosInstance
            .get("/masters/publications")
            .then((res: any) => {
                setPageState({
                    ...pageState,
                    publicationDropdownOption: generateDropDownOption(res.data.data)
                });
            })
            .catch(function (error) { });
    };

    const getCirculationExecutiveReportFields = (payload: number[]) => {
        return axiosInstance
            .post("/reports/region-wise-circulation-executive", payload)
            .then((res) => {
                setPageState({
                    ...pageState,
                    circulationExecutiveDropdownOption:
                        generateDropDownOption(res.data)
                });
            })
            .catch(function (error) { });
    };
    const getCityHeadReportFields = (payload: number[]) => {
        return axiosInstance
            .post("/reports/region-wise-city-head", payload)
            .then((res) => {
                setPageState({
                    ...pageState,
                    cityHeadDropdownOption:
                        generateDropDownOption(res.data)
                });
            })
            .catch(function (error) { });
    };

    const getParcelDepotReportFields = (payload: any) => {
        return axiosInstance
            .post("/reports/region-wise-parcel-depot-web", payload)
            // .post("/reports/region-wise-parcel-depot", payload)
            .then((res) => {
                if (res.status === 200 && res.data.length) {
                    let myRes = [];
                    if (pageState.selectedReportType !== "SAMPLE_COPY") {
                        myRes = res.data.filter((el: any) => !el.name.toLowerCase().includes("sampling"))
                    } else {
                        myRes = res.data.filter((el: any) => el.name.toLowerCase().includes("sampling"))
                    }
                    setPageState({
                        ...pageState,
                        parcelDepotDropdownOption:
                            generateDropDownOption(myRes)
                    });
                } else {
                    setPageState({ ...pageState, parcelDepotDropdownOption: [] });
                }
            })
            .catch(function (error) { });
    };

    const handleSearch = () => {
        let payload: any = {
            report_name: pageState.selectedReportType,
            start_date: pageState.start_date,
            end_date: pageState.end_date,
            regions: pageState.selectedRegion,
            branch: pageState.selectedBranch,
            depot_or_parcels: pageState.selectedParcelDepot,
            employee_type: pageState.selectedEmployeeType,
            publications: pageState.selectedPublication,
            attendance_flag: (pageState.selectedAttendance.length === ATTENDANCE_FLAG.length || pageState.selectedAttendance.length === 0) ? null : pageState.selectedAttendance,
            payment_mode: pageState.selectedPaymentMode,
            coupon_status: pageState.selectedCouponStatus,
            po_status: pageState.selectedPoStatus,
            po_slot: pageState.selectedPostingSlot,
            type: pageState.selectedType
        };

        if (pageState.selectedReportType === "CIRCULATION_EXE_WISE_SUPPLY" || pageState.selectedReportType === "CIRCULATION_WISE_PRINT_ORDER" || pageState.selectedReportType === "EXECUTIVE_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT") {
            payload.circulation_executives = pageState.selectedCirculationExecutive;
        }
        if (pageState.selectedReportType === "EXECUTIVE_WISE_COLLECTION") {
            payload.collection_executives = pageState.selectedCollectionExecutive;
        }
        if (pageState.selectedReportType === "PHYSICAL_RETURN") {
            payload.point_type = pageState.selectedType;
        }
        if (pageState.selectedReportType === "CITYHEAD_WISE_PRINT_ORDER" || pageState.selectedReportType === "CITYHEAD_WISE_COLLECTION") {
            payload.city_heads = role === "City Head" ? [user.id] : pageState.selectedCityHead;
        }
        payload.collection_executives = (
            payload.collection_executives || []
        ).map((id: number) => id && id.toString());
        payload.circulation_executives = (
            payload.circulation_executives || []
        ).map((id: number) => id && id.toString());
        payload.depot_or_parcels = (payload.depot_or_parcels || []).map(
            (id: number) => id && id.toString()
        );
        payload.regions = (payload.regions || []).map(
            (id: number) => id && id.toString()
        );
        payload.publications = (payload.publications || []).map(
            (id: number) => id && id.toString()
        );
        axiosInstance
            .post("/reports/data", payload)
            .then((res) => {
                setRowData(res.data);
            })
            .catch(function (error) {
                setShowSnack(true);
                console.log(error);
            });
    };


    useEffect(() => {
        if (pageState.selectedRegion.length) {
            circulationExec(() => {
                switch (pageState.selectedReportType) {
                    case "CIRCULATION_EXE_WISE_SUPPLY":
                    case "PRINT_ORDER":
                    case "CIRCULATION_WISE_PRINT_ORDER":
                    case "EXECUTIVE_WISE_DAILY_SALES_REPORT":
                    case "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT":
                        return getCirculationExecutiveReportFields(
                            pageState.selectedRegion
                        );
                    case "CITYHEAD_WISE_PRINT_ORDER":
                    case "CITYHEAD_WISE_COLLECTION":
                        return getCityHeadReportFields(pageState.selectedRegion)
                    case "DEPOT_WISE_COLLECTION":
                    case "DEPOT_WISE_DAILY_SALES_REPORT":
                        return getParcelDepotReportFields({
                            regions: pageState.selectedRegion,
                            type: ["DEPOT"],
                        });
                    case "PARCEL_WISE_COLLECTION":
                        return getParcelDepotReportFields({
                            regions: pageState.selectedRegion,
                            type: ["PARCEL"],
                        });
                    case "COUPON_STATUS_WISE":
                    case "PAYMENT_OPTION_WISE":
                    case "PRINT_ORDER":
                    case "SAMPLE_COPY":
                    case "PAYMENT":
                        return getParcelDepotReportFields({
                            regions: pageState.selectedRegion,
                            type: pageState.selectedType,
                        });
                    default:
                        return Promise.resolve();
                }
            });
        }
        else {
            setPageState({ ...pageState, parcelDepotDropdownOption: [], selectedType: [], selectedCityHead: [] });
        }
    }, [pageState.selectedRegion])

    useEffect(() => {
        if (pageState.selectedType.length) {
            circulationExec(() => {
                switch (pageState.selectedReportType) {
                    case "PHYSICAL_RETURN":
                    case "COUPON_STATUS_WISE":
                    case "PAYMENT_OPTION_WISE":
                    case "PRINT_ORDER":
                    case "SAMPLE_COPY":
                        return getParcelDepotReportFields({
                            regions: pageState.selectedRegion,
                            type: pageState.selectedType,
                        });
                    default:
                        return Promise.resolve();
                }
            });
        }
    }, [pageState.selectedType])


    useEffect(() => {
        setPageState({
            ...pageState,
            selectedParcelDepot: [],
            selectedRegion: [],
            selectedBranch: [],
            selectedCouponStatus: [],
            selectedPaymentMode: [],
            selectedPublication: [],
            selectedCirculationExecutive: [],
            selectedPoStatus: [],
            selectedPostingSlot: []
        })
        if (pageState.selectedReportType === "CIRCULATION_EXE_WISE_SUPPLY" || pageState.selectedReportType === "PHYSICAL_RETURN" || pageState.selectedReportType === "PRINT_ORDER" || pageState.selectedReportType === "BRANCH_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "REGION_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "EXECUTIVE_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_WISE_DAILY_SALES_REPORT") {
            getPublicationReportFields()
        }
    }, [pageState.selectedReportType]);


    const getUserRoleDropdown = () => {
        if (role === "Regional Manager") {
            return SUB_EMPLOYEE_USER_ROLES.filter(item => item !== "Regional Manager" && item !== "City Head").map((role: any) => ({
                label: role,
                id: role,
            }))
        }
        if (role === "City Head") {
            return SUB_EMPLOYEE_USER_ROLES.filter(item => item !== "City Head").map((role: any) => ({
                label: role,
                id: role,
            }))
        }
        return SUB_EMPLOYEE_USER_ROLES.map((role: any) => ({
            label: role,
            id: role,
        }))
    }

    useEffect(() => {
        setPageState({
            ...pageState,
            selectedParcelDepot: [],
        })
    }, [pageState.selectedType])

    return (
        <div>
            <PageHeader
                header="Reports"
                breadCrumb={["Dashboard", "Reports"]}
            />
            <div className="table_filter_wrapper">
                <div className="table_filter table_filter_columns_4">
                    <div className="filter_field">
                        <label className="filter_field_label">Range</label>
                        <div className="filter_field_date filter_field_date_range">
                            <input
                                style={{
                                    padding: ".8rem .6rem",
                                    background: "#f6f6f7",
                                    fontSize: 12,
                                    borderRadius: "4px",
                                    border: 0,
                                }}
                                type={"date"}
                                name="from"
                                value={pageState.start_date}
                                // min={
                                //     subtractNDays(
                                //         7,
                                //         new Date(pageState.end_date)
                                //     )
                                //         .toISOString()
                                //         .split("T")[0]
                                // }
                                // max={pageState.end_date}
                                onChange={(e) =>
                                    setPageState({
                                        start_date: new Date(
                                            e.target.value
                                        )
                                            .toISOString()
                                            .split("T")[0],
                                    })
                                }
                            />
                            <input
                                style={{
                                    padding: ".8rem .6rem",
                                    background: "#f6f6f7",
                                    fontSize: 12,
                                    borderRadius: "4px",
                                    border: 0,
                                }}
                                type={"date"}
                                name="to"
                                value={pageState.end_date}
                                min={pageState.start_date}
                                // max={
                                //     (pageState.selectedReportType === "PRINT_ORDER" ||
                                //         pageState.selectedReportType === "CITYHEAD_WISE_PRINT_ORDER" ||
                                //         pageState.selectedReportType === "CIRCULATION_WISE_PRINT_ORDER" ||
                                //         pageState.selectedReportType === "SAMPLE_COPY") ?
                                //         addNDays(
                                //             30,
                                //             new Date(pageState.end_date)
                                //         )
                                //             .toISOString()
                                //             .split("T")[0]
                                //         :
                                //         new Date()
                                //             .toISOString()
                                //             .split("T")[0]
                                // }
                                onChange={(e) =>
                                    setPageState({
                                        end_date: new Date(
                                            e.target.value
                                        )
                                            .toISOString()
                                            .split("T")[0],
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="filter_field">
                        <Select
                            multi={false}
                            disabled={loading}
                            name="selectedReportType"
                            selectedId={pageState.selectedReportType}
                            label="Report Type *"
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={REPORT_TYPE.filter((el: any) => el.permittedRole.includes(role)).map((reportType: any) => ({
                                label: reportType.name,
                                id: reportType.id,
                                value: reportType.name,
                            }))}
                        />
                    </div>

                    {pageState.selectedReportType && (
                        <>
                            {(ROLE_VS_PERMISSIONS[role]?.report.branchDropdown) &&
                                <div className="filter_field">
                                    <Select
                                        label="Branch *"
                                        multi={true}
                                        name="selectedBranch"
                                        selectedId={
                                            pageState.selectedBranch
                                        }
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={[
                                            { label: "Mumbai", id: "1" },
                                        ]}
                                    />
                                </div>
                            }
                            {pageState.selectedReportType !== "BRANCH_WISE_DAILY_SALES_REPORT" &&
                                <div className="filter_field">
                                    <Select
                                        label="Region *"
                                        multi={true}
                                        disabled={
                                            !ROLE_VS_PERMISSIONS[role]?.report
                                                ?.regionDropdown
                                        }
                                        name="selectedRegion"
                                        selectedId={
                                            pageState.selectedRegion
                                        }
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={pageState.regionDropdownOption}
                                    />
                                </div>
                            }
                            {/* <div className="filter_field">
                                <Select
                                    label="Collection Exec *"
                                    multi={true}
                                    disabled={loading}
                                    name="selectedCollectionExecutive"
                                    selectedId={pageState.selectedCollectionExecutive}
                                    optionAccessor="label"
                                    onChange={handleInputChange}
                                    options={
                                        pageState.collectionExecutiveDropdownOption
                                    }
                                />
                            </div> */}


                            {pageState.selectedReportType === "ATTENDANCE_REPORT" && <>
                                <div className="filter_field">
                                    <Select
                                        label="Employee Type *"
                                        multi={false}
                                        disabled={loading}
                                        name="selectedEmployeeType"
                                        selectedId={pageState.selectedEmployeeType}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={getUserRoleDropdown()}
                                    />
                                </div>
                                <div className="filter_field">
                                    <Select
                                        label="Attendance Flag"
                                        multi={true}
                                        name="selectedAttendance"
                                        selectedId={pageState.selectedAttendance}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={ATTENDANCE_FLAG}
                                    />
                                </div></>
                            }

                            {(pageState.selectedReportType === "PHYSICAL_RETURN" ||
                                pageState.selectedReportType === "PAYMENT_OPTION_WISE" ||
                                pageState.selectedReportType === "COUPON_STATUS_WISE" ||
                                pageState.selectedReportType === "PRINT_ORDER" ||
                                pageState.selectedReportType === "SAMPLE_COPY" ||
                                pageState.selectedReportType === "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT"
                            ) &&


                                <div className="filter_field">
                                    <Select
                                        label="Type"
                                        disabled={pageState.selectedRegion.length === 0}
                                        multi={true}
                                        name="selectedType"
                                        selectedId={pageState.selectedType}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={TYPE}
                                    />
                                </div>

                            }
                            {(pageState.selectedReportType === "PHYSICAL_RETURN" ||
                                pageState.selectedReportType === "PAYMENT_OPTION_WISE" ||
                                pageState.selectedReportType === "COUPON_STATUS_WISE" ||
                                pageState.selectedReportType === "PRINT_ORDER" ||
                                pageState.selectedReportType === "SAMPLE_COPY"
                            ) &&

                                <div className="filter_field">
                                    <Select
                                        disabled={pageState.selectedType.length === 0}
                                        loading={circulationExecLoading}
                                        label="Parcel/Depot *"
                                        multi={true}
                                        name="selectedParcelDepot"
                                        selectedId={pageState.selectedParcelDepot}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={pageState.parcelDepotDropdownOption}
                                    />
                                </div>

                            }
                            {(pageState.selectedReportType ===
                                "DEPOT_WISE_COLLECTION" || pageState.selectedReportType ===
                                "PARCEL_WISE_COLLECTION" || pageState.selectedReportType === "DEPOT_WISE_DAILY_SALES_REPORT") &&
                                <div className="filter_field">
                                    <Select
                                        disabled={pageState.selectedRegion.length === 0}
                                        loading={circulationExecLoading}
                                        label={pageState.selectedReportType ===
                                            "PARCEL_WISE_COLLECTION" ? "Parcel *" : "Depot *"}
                                        multi={true}
                                        name="selectedParcelDepot"
                                        selectedId={pageState.selectedParcelDepot}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={pageState.parcelDepotDropdownOption}
                                    />
                                </div>
                            }

                            {(pageState.selectedReportType === "COUPON_STATUS_WISE")
                                &&
                                <div className="filter_field">
                                    <Select
                                        label="Coupon Status"
                                        multi={true}
                                        name="selectedCouponStatus"
                                        selectedId={pageState.selectedCouponStatus}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={[
                                            { label: "Approved", id: "APPROVED" },
                                            { label: "Rejected", id: "REJECTED" },
                                        ]}
                                    />
                                </div>
                            }
                            {(pageState.selectedReportType === "CIRCULATION_EXE_WISE_SUPPLY" || pageState.selectedReportType === "CIRCULATION_WISE_PRINT_ORDER" || pageState.selectedReportType === "EXECUTIVE_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT") &&
                                <div className="filter_field">
                                    <Select
                                        disabled={pageState.selectedRegion.length === 0}
                                        label="Circulation Executive *"
                                        multi={true}
                                        loading={circulationExecLoading}
                                        name="selectedCirculationExecutive"
                                        selectedId={
                                            pageState.selectedCirculationExecutive
                                        }
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={
                                            pageState.circulationExecutiveDropdownOption
                                        }
                                    />
                                </div>
                            }
                            {(pageState.selectedReportType === "CIRCULATION_EXE_WISE_SUPPLY" || pageState.selectedReportType === "PHYSICAL_RETURN" || pageState.selectedReportType === "PRINT_ORDER" || pageState.selectedReportType === "BRANCH_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "REGION_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "EXECUTIVE_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT" || pageState.selectedReportType === "DEPOT_WISE_DAILY_SALES_REPORT") &&
                                <div className="filter_field">
                                    <Select
                                        label="Publication *"
                                        multi={true}
                                        disabled={loading}
                                        name="selectedPublication"
                                        selectedId={pageState.selectedPublication}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={pageState.publicationDropdownOption}
                                    />
                                </div>
                            }
                            {(role !== "City Head" && (pageState.selectedReportType === "CITYHEAD_WISE_PRINT_ORDER" || pageState.selectedReportType === "CITYHEAD_WISE_COLLECTION")) &&
                                <div className="filter_field">
                                    <Select
                                        disabled={pageState.selectedRegion.length === 0}
                                        label="City Head *"
                                        multi={true}
                                        loading={cityHeadLoading}
                                        name="selectedCityHead"
                                        selectedId={
                                            pageState.selectedCityHead
                                        }
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={
                                            pageState.cityHeadDropdownOption
                                        }
                                    />
                                </div>
                            }
                            {(pageState.selectedReportType === "PAYMENT_OPTION_WISE") &&
                                <div className="filter_field">
                                    <Select
                                        label="Payment Option"
                                        multi={true}
                                        name="selectedPaymentMode"
                                        selectedId={pageState.selectedPaymentMode}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={[
                                            { label: "Cash", id: "CASH" },
                                            { label: "Online", id: "ONLINE" },
                                            { label: "Cheque", id: "CHEQUE" },
                                            { label: "Coupon", id: "COUPON" },
                                        ]}
                                    />
                                </div>
                            }
                            {(pageState.selectedReportType === "PRINT_ORDER" || pageState.selectedReportType === "CIRCULATION_WISE_PRINT_ORDER" || pageState.selectedReportType === "SAMPLE_COPY" || pageState.selectedReportType === "CITYHEAD_WISE_PRINT_ORDER") &&
                                <div className="filter_field">
                                    <Select
                                        label="Verification Status"
                                        multi={true}
                                        name="selectedPoStatus"
                                        selectedId={pageState.selectedPoStatus}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={[
                                            { label: "Pending", id: "PENDING" },
                                            { label: "Approved", id: "APPROVED" },
                                            { label: "Approved-CH", id: "APPROVED-BY-CH" },
                                            { label: "Approved-BH", id: "FINALAPPROVED" },
                                            { label: "Rejected", id: "REJECTED" },
                                        ]}
                                    />
                                </div>
                            }
                            {(pageState.selectedReportType === "PRINT_ORDER" || pageState.selectedReportType === "CIRCULATION_WISE_PRINT_ORDER" || pageState.selectedReportType === "CITYHEAD_WISE_PRINT_ORDER") &&
                                <div className="filter_field">
                                    <Select
                                        label="Posting Slot"
                                        multi={true}
                                        name="selectedPostingSlot"
                                        selectedId={pageState.selectedPostingSlot}
                                        optionAccessor="label"
                                        onChange={handleInputChange}
                                        options={[
                                            { label: "Day", id: "DAY" },
                                            { label: "Night", id: "NIGHT" },
                                        ]}
                                    />
                                </div>
                            }

                        </>
                    )}
                </div>
                <div className="table_filter_button" >
                    <Button onClick={handleSearch}>Search</Button>
                </div>
            </div>
            <AgGridWrapper
                header={`Report: ${REPORT_TYPE.find(el => el.id === pageState.selectedReportType)?.name || ''}`}
                // convertToProperCase(pageState.selectedReportType)}`}
                rowData={rowData || []}
                columnDefs={getColumnDefs()}
                exportData={true}
                fileName={pageState.selectedReportType}
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={showSnack}
                autoHideDuration={6000}
                onClose={(e) => setShowSnack(false)}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={(e) => setShowSnack(false)}
                    severity={"error"}
                >
                    Please select all the required field first.
                </MuiAlert>
            </Snackbar>
        </div>
    );
};
export default ReportsPage;
