import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import { axiosInstance } from "../../../state/axios";
import Input from "../../base/Input";
import {
    getUnMappedUsers,
    getCenters,
    updateUnmappedUser,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import map from "../../../assets/icons/map.png";
import Select from "../../base/Select";
import AgGridWrapper from "../../base/AgGridWrapper";
import { generateDropDownOption } from "../../../utils";
import { useAuthContext } from "../../../context/auth";
import { WEB_ROLES } from "../../../constants";

const UnmappedUsers = () => {
    const { user } = useAuthContext();
    const { exec, data } = useQuery();
    const { exec: execCenter, data: centerData } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [centerDropdownOption, setCenterDropdownOption] = useState<any[]>([]);
    const [formState, handleInputChange, resetState, setInitialState, dirty] =
        useFormState<any>({});
    useEffect(() => {
        if (centerData) {
            let dataVal: any = [];
            centerData.map((tempData: any) => {
                let object = {
                    label: tempData.name,
                    id: tempData.id,
                    value: tempData.id,
                };
                dataVal.push(object);
            });
            setCenterDropdownOption(dataVal);
        }
    }, [centerData]);
    useEffect(() => {
        if (formState.region_id)
            execCenter(() => getCenters("", formState.region_id));
    }, [formState.region_id]);
    const dataColumnDefs = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Bill To Code",
            field: "bill_code",
        },
        {
            headerName: "Ship To code",
            field: "ship_to_code",
        },
        {
            headerName: "Action to Mapped",
            field: "#",
            cellRenderer: ({ data }: any) => {
                return (
                    <img
                        src={map}
                        alt="Mapped"
                        onClick={() => {
                            setShowModal(true);
                            handleInputChange({
                                name: data.name,
                                bill_code: data.bill_code,
                                ship_to_code: data.ship_to_code,
                                id: data.id,
                            });
                        }}
                    />
                );
            },
        },
    ];
    useEffect(() => {
        exec(() => getUnMappedUsers());
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    return (
        <>
            <PageHeader
                header="Unmapped Users"
                breadCrumb={["Dashboard", "Master", "Unmapped Users"]}
            />
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />

            <Modal
                header={<h3>Update Unmapped user</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (
                            !formState.roles ||
                            !formState.region_id ||
                            !formState.centers_id
                        ) {
                            alert("Please select required fields.");
                        } else {
                            const mapped = await updateUnmappedUser(formState);
                            if (mapped !== "error") {
                                exec(() => getUnMappedUsers());
                                alert("User mapped successfully.");
                            } else {
                                alert(
                                    "An error occurred while processing request. Please try again"
                                );
                            }
                            setShowModal(false);
                        }
                    }}
                >
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <Input
                            disabled
                            label="Name"
                            style={{ flex: 1 }}
                            name="name"
                            onChange={handleInputChange}
                            value={formState.name}
                        />

                        <Input
                            required
                            label="Email *"
                            style={{ flex: 1 }}
                            name="email"
                            onChange={handleInputChange}
                            value={formState.email}
                        />
                    </div>
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <Input
                            required
                            label="Phone No *"
                            type="number"
                            style={{ flex: 1 }}
                            name="phone"
                            onChange={handleInputChange}
                            value={formState.phone}
                        />

                        <Select
                            multi={false}
                            className="userSelect"
                            name="roles"
                            selectedId={formState.roles}
                            label="Role *"
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={[
                                {
                                    label: "Parcel Vendor",
                                    id: "Parcel Vendor",
                                },
                                {
                                    label: "Depot Salesman",
                                    id: "Depot Salesman",
                                },
                            ]}
                        />
                    </div>
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <Select
                            multi={false}
                            className="userSelect"
                            name="region_id"
                            selectedId={formState.region_id}
                            label="Region *"
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={generateDropDownOption(user.region_details, "description")}
                        />

                        <Select
                            multi={false}
                            className="userSelect"
                            name="centers_id"
                            selectedId={formState.centers_id}
                            label="Centers *"
                            optionAccessor="label"
                            onChange={({ centers_id }) => {
                                const center: any = centerDropdownOption.filter(
                                    (item) => {
                                        return item.id === centers_id;
                                    }
                                );
                                handleInputChange({
                                    centers_id,
                                    center_name: center[0].label,
                                });
                            }}
                            options={centerDropdownOption || []}
                        />
                    </div>
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <Input
                            disabled
                            label="Bill to Code"
                            style={{ flex: 1 }}
                            name="bill_code"
                            value={formState.bill_code}
                            onChange={handleInputChange}
                        />

                        <Input
                            disabled
                            label="Ship to Code"
                            style={{ flex: 1 }}
                            name="ship_to_code"
                            onChange={handleInputChange}
                            value={formState.ship_to_code}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit" disabled={!dirty}>
                            Update
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default UnmappedUsers;
