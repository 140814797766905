import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import Input from "../../base/Input";
import {
    getAllParcelDepots,
    updateParcelDepots,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import map from "../../../assets/icons/map.png";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ParcelDepot = () => {
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [formState, handleInputChange, resetState, setInitialState, dirty] = useFormState<any>({});
    const dataColumnDefs = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Parcel/Depot",
            field: "type",
        },
        {
            headerName: "Latitude",
            field: "check_in_latitude",
        },
        {
            headerName: "Longitude",
            field: "check_in_longitude",
        },
        {
            headerName: "Address",
            field: "depot_address",
        },
        {
            headerName: "Ship To code",
            field: "ship_to_code",
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                            // className={styles.editIcon}

                            icon={faPen}
                            onClick={() => {
                                setShowModal(true);
                                handleInputChange({
                                    check_in_latitude: data.check_in_latitude,
                                    check_in_longitude: data.check_in_longitude,
                                    depot_address: data.depot_address,
                                    id: data.id,
                                });
                            }}
                        />
                    </div>

                );
            },
        },
    ];
    useEffect(() => {
        exec(() => getAllParcelDepots());
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    return (
        <>
            <PageHeader
                header="Address"
                breadCrumb={["Dashboard", "Master", "Address"]}
            />
            <AgGridWrapper
                header={"Addresses"}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                search={true}
            />

            <Modal
                header={<h3>Update Address</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (
                            !formState.check_in_latitude ||
                            !formState.check_in_longitude ||
                            !formState.depot_address
                        ) {
                            alert("Please select required fields.");
                        } else {
                            const res = await updateParcelDepots(formState);
                            if (res !== "error") {
                                exec(() => getAllParcelDepots());
                                alert("Address updated successfully.");
                            } else {
                                alert(
                                    "An error occurred while processing request. Please try again"
                                );
                            }
                            setShowModal(false);
                        }
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <Input
                            label="Latitude*"
                            style={{ flex: 1 }}
                            name="check_in_latitude"
                            onChange={handleInputChange}
                            value={formState.check_in_latitude}
                        />

                        <Input
                            required
                            label="Longitude*"
                            style={{ flex: 1 }}
                            name="check_in_longitude"
                            onChange={handleInputChange}
                            value={formState.check_in_longitude}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <Input
                            required
                            label="Address*"
                            style={{ flex: 1 }}
                            name="depot_address"
                            onChange={handleInputChange}
                            value={formState.depot_address}
                        />
                    </div>

                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit" disabled={!dirty}>
                            Update
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default ParcelDepot;
