import { error } from "console";
import { axiosInstance } from "../axios";

export const getStates = () => {
    return axiosInstance
        .get("/masters/states", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getCities = () => {
    return axiosInstance
        .get("/masters/cities", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getDistricts = () => {
    return axiosInstance
        .get("/masters/districts", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getUnMappedUsers = () => {
    return axiosInstance
        .get("/transaction/getAllUnMappedUser", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};
export const getRegions = (userId?: string) => {
    return axiosInstance
        .get("/masters/regions", {
            headers: { "Content-Type": "application/json" },
            ...(userId ? { params: { userId } } : {}),
        })
        .then(({ data }) => {
            localStorage.setItem("user_region", JSON.stringify(data.data[0]));
            return data.data;
        });
};

export const getCenters = (userId?: string, regionId?: string) => {
    return axiosInstance
        .get("/masters/centers", {
            headers: { "Content-Type": "application/json" },
            params: {
                ...(userId ? { userId } : {}),
                ...(regionId ? { regionId } : {}),
            },
        })
        .then(({ data }) => {
            return data.data;
        });
};

export const getCentersWeb = (regionId?: Array<string>) => {
    return axiosInstance
        .post("/masters/centers-web", { region_ids: regionId })
        .then(({ data }) => {
            return data.data;
        });
};

export const updateUnmappedUser = (payload: any) => {
    return axiosInstance
        .post("/transaction/addAndMapUsers", payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// =================================
//  MASTERS : PARCEL / DEPOTS
// =================================

// GET PARCEL/DEPOT BASED ON CENTER ID
export const getParcelDepots = (centerId?: string) => {
    return axiosInstance
        .get("/masters/parcel-depot", {
            headers: { "Content-Type": "application/json" },
            ...(centerId ? { params: { centerId } } : {}),
        })
        .then(({ data }) => {
            return data.data;
        });
};
// GET ALL PARCEL/DEPOT
export const getAllParcelDepots = () => {
    return axiosInstance
        .get("/masters/get-all-parcel-depot", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PARCEL/DEPOT

export const updateParcelDepots = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/update-parcel-depot/${payload.id}`, payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// GET REGION WISE PARCEL DEPOT
export const getParcelDepotsWeb = async (payload: any) => {
    return await axiosInstance
        .post("/masters/parcel-depot-web", { center_ids: payload })
        .then((res) => {
            const mydata = res.data.data.filter(
                (el: any) => !el.name.toLowerCase().includes("sampling")
            );
            return mydata;
        })
        .catch((error) => {
            return "error";
        });
};

// =================================
//  MASTERS : DEVICE REGISTRATION
// =================================

// GET REGISTERED DEVICE
export const getAllRegisteredDevice = () => {
    return axiosInstance
        .get("/masters/get-registered-device", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// DELETE REGISTERED DEVICE
export const deleteRegisteredDevice = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/delete-registered-device/${payload.id}`, payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// =================================
//  MASTERS : DAY/NIGHT REVISION
// =================================

export const AddPrintOrderRevisionMaster = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/save-revision-for-po`, payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// GET PARCEL ORDER REVISION
export const getPrintOrderRevision = (centerId?: string) => {
    return axiosInstance
        .get("/masters/get-revision-poTime", {
            headers: { "Content-Type": "application/json" },
            ...(centerId ? { params: { centerId } } : {}),
        })
        .then(({ data }) => {
            return data.data;
        });
};

// UPDATE PRINT ORDER REVISION
export const updatePrintOrderRevision = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/update-revision-for-po/${payload.id}`, payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// =================================
//  MASTERS : ATTENDANCE RADIUS
// =================================

// GET ATTENDANCE RADIUS
export const getAttendanceRadius = () => {
    return axiosInstance
        .get("/masters/get-radius", {
            headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
            return data.data;
        });
};

// ADD ATTENDANCE RADIUS
export const addAttendanceRadius = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/save-radius`, { radius: payload.radius })
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};

// UPDATE ATTENDANCE RADIUS
export const updateAttendanceRadius = async (payload: any) => {
    return await axiosInstance
        .post(`/masters/update-radius/${payload.id}`, payload)
        .then(({ data }) => {
            return data.code;
        })
        .catch((error) => {
            return "error";
        });
};
