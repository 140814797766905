import { faBars, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { logout } from "../../../state/actions/auth.action";
import Button from "../Button";
import styles from "./topBar.module.scss";
import { useAuthActionContext } from "../../../context/auth";

const TopBar = () => {
  const { Signout } = useAuthActionContext();
  const [sideNavOpen, setSideNavOpen] = useState(true);
  return (
    <div className={styles.wrapper}>
      <div
        className={[styles.left].join(" ")}
        onClick={() => {
          if (sideNavOpen) {
            document.documentElement.style.setProperty("--side-nav-width", "0");
          } else {
            document.documentElement.style.setProperty(
              "--side-nav-width",
              "18rem"
            );
          }
          setSideNavOpen(!sideNavOpen);
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div className={styles.right}>
        <Button onClick={Signout}>
          <FontAwesomeIcon icon={faPowerOff} style={{ marginRight: "5px" }} />
          Logout
        </Button>
      </div>
    </div>
  );
};

export default TopBar;
