import { useState } from 'react';
import _isEqual from 'lodash-es/isEqual';

export function useFormState<T>(
  initialState: T
): [T, (obj: Partial<T>) => void, () => void, (obj: Partial<T>) => void, boolean] {
  const [formState, setFormState] = useState<T>(initialState);
  const [_initialState, _setInitialState] = useState<T>(initialState);

  const [dirty, setDirty] = useState<boolean>(false);


  const checkDirty = (data: any) => {
    _isEqual(_initialState, data) ? setDirty(false) : setDirty(true);
  };

  const setInitialState = (data: Partial<T>) => {
    const newData = { ...formState, ...data };
    _setInitialState(newData);
    handleInputChange(newData);
    setDirty(false);
  };

  const handleInputChange = (valObj: Partial<T>) => {

    const newData = { ...formState, ...valObj };
    setFormState(newData);
    checkDirty(newData);
  };
  const resetState = () => setFormState({} as T);

  return [formState, handleInputChange, resetState, setInitialState, dirty];
}
