import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import Input from "../../base/Input";
import PageHeader from "../../base/PageHeader";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faCheckCircle,
    faPen,
    faTimesCircle,
    faPlusCircle,
    faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPrintOrderSample, updatePrintOrderStatusSample, updatePrintOrderTradeSample } from "../../../state/actions/print.action";
import { useAuthContext } from "../../../context/auth";
import { WEB_ROLES } from "../../../constants";
import ConfirmationModal from "../../base/ConfirmationModal";
import "./printOrder.scss";
import { convertToProperCase } from "../../../utils";

const PrintOrder = ({ selectedView }: { selectedView: string }) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.login_id;
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [actionStatus, setActionStatus] = useState("");
    const [actionId, setActionId] = useState<string | number>("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [formState, handleInputChange, resetState, setInitialState, dirty] = useFormState<any>({});

    const isEditable = (requestStatus: string, role: string) => {
        if (requestStatus === "PENDING" && (role === "Regional Manager" || role === "City Head" || role === "Branch Head")) {
            return true
        }
        if (requestStatus === "APPROVED" && (role === "City Head" || role === "Branch Head")) {
            return true
        }
        if (requestStatus === "APPROVED-BY-CH" && role === "Branch Head") {
            return true
        }
        return false
    }
    const dataColumnDefs = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "From Date",
            field: "to_show_formatted_from_date",
        },
        {
            headerName: "To Date",
            field: "to_show_formatted_to_date",
        },
        {
            headerName: "Status",
            field: "status",
            valueGetter: ({ data }: any) => {
                if (data.status === "FINALAPPROVED") {
                    return "Approved-BH"
                }
                if (data.status === "APPROVED-BY-CH") {
                    return "Approved-CH"
                }
                return convertToProperCase(data.status)
            },
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                if (isEditable(data.status, role)) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPen}
                                size="2x"
                                style={{
                                    color: "grey",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedRow(data)
                                    handleInputChange({ publications: [...data.publications] })
                                }}
                            />
                            <FontAwesomeIcon
                                onClick={() => handleAction(
                                    data.print_order_id,
                                    role === "Regional Manager" ? "APPROVED" : role === "City Head" ? "APPROVED-BY-CH" : "FINALAPPROVED"
                                )}
                                size="2x"
                                style={{
                                    color: "green",
                                    cursor: "pointer",
                                }}
                                title={role === "Regional Manager" ? "APPROVED" : role === "City Head" ? "APPROVED-BY-CH" : "FINALAPPROVED"}
                                icon={faCheckCircle}
                            />
                            <FontAwesomeIcon
                                onClick={() => handleAction(
                                    data.print_order_id,
                                    "REJECTED"
                                )}
                                size="2x"
                                style={{
                                    color: "red",
                                    cursor: "pointer",
                                }}
                                icon={faTimesCircle}
                            />
                        </div>
                    );
                }
            },
        },
    ];
    useEffect(() => {
        exec(() => getPrintOrderSample(userID));
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);



    const handleAction = (id: string | number, status: string,) => {
        if (status === "REJECTED") {
            setActionId(id)
            setActionStatus(status)
            setShowConfirmationModal(true)
        } else {
            handleConfirmation(id, status)
        }
    };

    const handleConfirmation = (id: string | number, status: string,) => {
        exec(() =>
            updatePrintOrderStatusSample({
                user_id: userID,
                status: status,
                print_order_id: id,
                remarks: remarks,
            }).then(() => {
                setShowConfirmationModal(false)
                exec(() => getPrintOrderSample(userID));
            })
        );

    };

    const handleChange = (value: any, id: number) => {
        let newmapee = formState.publications.map((pub: any) => {
            return {
                ...pub,
                updated_value: id === pub.id ? Number(value.updated_value) : Number(pub.updated_value)
            }

        })
        handleInputChange({ publications: [...newmapee] })
    }

    return (
        <>
            {/* <PageHeader
                header="Print Order Sampling"
                breadCrumb={["Dashboard", "Print Order Sampling"]}
            /> */}
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />

            <Modal
                header={<h3>Update Sampling Copy</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        // if (
                        //     !formState.check_in_latitude ||
                        //     !formState.check_in_longitude ||
                        //     !formState.depot_address
                        // ) {
                        //     alert("Please select required fields.");
                        // } else {
                        const payload = {
                            ...formState,
                            user_id: userID,
                            print_order_id: selectedRow?.print_order_id,
                            status: selectedRow?.status
                        }
                        const res = await updatePrintOrderTradeSample(payload);
                        if (res !== "error") {
                            exec(() => getPrintOrderSample(userID));
                            alert("Print Order updated successfully.");
                        } else {
                            alert(
                                "An error occurred while processing request. Please try again"
                            );
                        }
                        setShowModal(false);
                        // }
                    }}
                >
                    {formState?.publications?.map((el: any, index: number) => {

                        return <div key={`${el.trade_name}-${el.id}`} className="update-po-input-wrapper">
                            <Input
                                label={`Sampling Copy (${el.trade_name})`}
                                style={{ flex: 1 }}
                                name="updated_value"
                                onChange={(value) => {
                                    return handleChange(value, el.id)
                                }}
                                value={el.updated_value}
                            />

                        </div>
                    })}

                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit">
                            Update
                        </Button>
                    </div>
                </form>
            </Modal>
            <ConfirmationModal
                header={"Remarks"}
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <div style={{ display: "flex", flexFlow: "column wrap" }}>
                    <input
                        type={"text"}
                        name="remarks"
                        onChange={(e) => {
                            setRemarks(e.currentTarget.value,
                            );
                        }}
                        placeholder="Please enter remarks"
                        style={{
                            height: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '4px 10px',
                        }}
                    />
                    <p style={{ margin: 0, marginTop: '.5rem' }}>You are about to perform an action that cannot be undone. Please confirm if you want to proceed.</p>
                    <div style={{ display: "flex", columnGap: "8px", justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button className="default" type="button" onClick={() => setShowConfirmationModal(false)}>
                            No
                        </Button>
                        <Button type="button" onClick={() => handleConfirmation(actionId, actionStatus)}>
                            Yes
                        </Button>
                    </div>
                </div>

            </ConfirmationModal>
        </>
    );
};

export default PrintOrder;
