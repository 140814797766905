import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import Input from "../../base/Input";
import {
    getPrintOrderRevision,
    AddPrintOrderRevisionMaster,
    updatePrintOrderRevision,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import map from "../../../assets/icons/map.png";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REVISION_TYPE } from "../../../constants";
import Select from "../../base/Select";
import { convertToProperCase } from "../../../utils";


const Revision = () => {
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [formState, handleInputChange, resetState, setInitialState, dirty] = useFormState<any>({});
    const dataColumnDefs = [
        {
            headerName: "Revision Type",
            field: "revision",
            valueGetter: ({ data }: any) => {
                return convertToProperCase(data.revision)
            },
        },
        {
            headerName: "From Time",
            field: "revision_from",
        },
        {
            headerName: "To Time",
            field: "revision_to",
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                            icon={faPen}
                            onClick={() => {
                                setShowModal(true);
                                handleInputChange({
                                    revision_from: data.revision_from,
                                    revision_to: data.revision_to,
                                    revision: data.revision,
                                    id: data.id,
                                    edit: true,
                                });
                            }}
                        />
                    </div>

                );
            },
        },
    ];
    useEffect(() => {
        exec(() => getPrintOrderRevision());
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);

    return (
        <>
            <PageHeader
                header="PO Revision Shift"
                breadCrumb={["Dashboard", "Master", "PO Revision Shift"]}
                rightSection={
                    (rowData === null || rowData.length === 0 || rowData.length < 2) &&
                    <button onClick={() => {
                        setShowModal(true);
                        handleInputChange({
                            revision_from: "",
                            revision_to: "",
                            revision: "",
                            id: "",
                            edit: false,
                        });
                    }}>Add</button>
                }
            />
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />

            <Modal
                header={<h3>{formState.edit ? "Update" : "Add"} Revision</h3>}
                show={showModal}
                onClose={() => setShowModal(false)}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (
                            !formState.revision ||
                            !formState.revision_from ||
                            !formState.revision_to
                        ) {
                            alert("Please select required fields.");
                        } else {
                            if (formState.edit) {
                                const res = await updatePrintOrderRevision(formState);
                                if (res !== "error") {
                                    exec(() => getPrintOrderRevision());
                                    alert("Print Order Revision updated successfully.");
                                } else {
                                    alert(
                                        "An error occurred while processing request. Please try again"
                                    );
                                }
                                setShowModal(false);
                            } else {
                                const res = await AddPrintOrderRevisionMaster(formState);
                                if (res !== "error") {
                                    exec(() => getPrintOrderRevision());
                                    alert("Print Order Revision added successfully.");
                                } else {
                                    alert(
                                        "An error occurred while processing request. Please try again"
                                    );
                                }
                                setShowModal(false);
                            }
                        }
                    }}
                >
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                        <Select
                            disabled={formState.edit}
                            name="revision"
                            selectedId={formState.revision}
                            label="Revision*"
                            optionAccessor="label"
                            onChange={handleInputChange}
                            options={REVISION_TYPE.map((revision: any) => ({
                                label: revision.label,
                                id: revision.id,
                                value: revision.value,
                            }))}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <Input
                            type="time"
                            required
                            label="From*"
                            style={{ flex: 1 }}
                            name="revision_from"
                            onChange={handleInputChange}
                            value={formState.revision_from}
                        />

                        <Input
                            type="time"
                            required
                            label="To*"
                            style={{ flex: 1 }}
                            name="revision_to"
                            onChange={handleInputChange}
                            value={formState.revision_to}
                        />
                    </div>


                    <div
                        style={{
                            marginTop: "2rem",
                        }}
                    >
                        <Button type="submit" disabled={!dirty}>
                            {formState.edit ? "Update" : "Add"}
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default Revision;
