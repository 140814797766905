import axios from "axios";
import { baseURL } from "../constants";
// import useAuthStore from "./stores";

export const axiosInstance = axios.create({
    baseURL: baseURL,
});

axiosInstance.interceptors.request.use((conf) => {
    if (localStorage.getItem("token")) {
        const token = localStorage.getItem("token") || "";
        conf.headers = {
            ...conf.headers,
            Accept: "application/vnd.tiedn.ie.api.v1+json",
            "Content-Type": "application/vnd.tiedn.ie.api.v1+json",
            Authorization: token,
        };
    }

    return conf;
});

// axiosInstance.interceptors.response.use(
//     (res) => {
//         return res;
//     },
//     (err) => {
//         console.log("🚀 ~ inberceptor err:", err.response.status === 403);
//         if (err.response.status === 403) {
//             localStorage.clear();
//         }
//         window.location.replace("/login");
//         return Promise.reject(err);
//     }
// );
