import React, { useEffect, useState } from "react";
import { useQuery } from "../../../hooks";
import {
    getAllRegisteredDevice,
    deleteRegisteredDevice,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import AgGridWrapper from "../../base/AgGridWrapper";
import {
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeviceRegistration = () => {
    const { exec, data } = useQuery();
    const [rowData, setRowData] = useState<any>([]);
    const dataColumnDefs = [
        {
            headerName: "Login Id",
            field: "login_id",
        },
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Email",
            field: "email",
        },
        {
            headerName: "Role",
            field: "roles",
        },
        {
            headerName: "Action",
            field: "#",
            cellRenderer: ({ data }: any) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                                handleDelete(data.login_id);
                            }}
                        />
                    </div>

                );
            },
        },
    ];
    const handleDelete = (login_id: string) => {
        exec(() =>
            deleteRegisteredDevice({ id: login_id }).then(() => {
                exec(() => getAllRegisteredDevice());
            })
        );

    };
    useEffect(() => {
        exec(() => getAllRegisteredDevice());
    }, []);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    return (
        <>
            <PageHeader
                header="Device Registration"
                breadCrumb={["Dashboard", "Master", "Device Registration"]}
            />
            <AgGridWrapper
                header={""}
                rowData={rowData || []}
                columnDefs={dataColumnDefs}
                exportData={false}
            />
        </>
    );
};

export default DeviceRegistration;
