import { faPen, faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useState } from "react";
import { defaultColsDef, ROLES, USER_TYPE, WEB_ROLES } from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import { getCenters, getRegions } from "../../../state/actions/masters.action";
import {
    createUser,
    fetchUsers,
    getCenterOrRegionCode,
    IUser,
    updateUser,
    updateUserStatus,
} from "../../../state/actions/user.actions";
import Button from "../../base/Button";
import Input from "../../base/Input";
import Modal from "../../base/Modal";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
// import Table from "../../helper/Table";
// import { ITableConfig } from "../../helper/Table/Table";
import styles from "./userPage.module.scss";
// import { AgGridReact } from "ag-grid-react";
// import { customDateTimeFormat } from "../../../utils";
import AgGridWrapper from "../../base/AgGridWrapper";
import { useAuthContext } from "../../../context/auth";
import { generateDropDownOption } from "../../../utils";

const UserForm: FC<{ onSubmit: (formState: any) => void; value: any }> = ({
    onSubmit,
    value,
}) => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const [formState, handleInputChange, resetState, setInitialState, dirty] =
        useFormState(value);

    // const { exec: execRegion, data: regionData } = useQuery();
    const { exec: execCenter, data: centerData } = useQuery();
    const { exec: execCode, data: code } = useQuery();

    useEffect(() => {
        // const promiseArr = [
        //     execCenter(() => getCenters()),
        //     // execRegion(() => getRegions()),
        // ];
        execCenter(() => getCenters());
        if (value.id) {
            // promiseArr.push(
            execCode(() => getCenterOrRegionCode(value.id, value.roles))
            // );
        }

        // Promise.all(promiseArr).then((d) => { });
    }, [value]);

    useEffect(() => {
        if (code)
            handleInputChange({
                add_update_location_pk_id: code,
            });
    }, [code]);

    const getRegionOrCenters = () => {
        return (
            (formState.roles === "Circulation Executive" ||
                formState.roles === "Collection Executive" ||
                formState.roles === "Parcel Vendor" ||
                formState.roles === "Depot Salesman"
                ? centerData
                : user?.region_details) || []
        ).map(({ id, name }: { id: string; name: string }) => ({
            label: name,
            id: id,
        }));
    };

    return (
        <form
            style={{ width: "30rem" }}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(formState);
            }}
        >
            <div>
                <Input
                    required
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={handleInputChange}
                />
            </div>
            <div style={{ display: "flex" }}>
                <Input
                    required
                    minLength={8}
                    label="Login ID"
                    name="login_id"
                    value={formState.login_id}
                    onChange={handleInputChange}
                />
                <Input
                    required
                    type="password"
                    minLength={8}
                    label="Password"
                    name="password"
                    placeholder="*****"
                    value={formState.password}
                    onChange={handleInputChange}
                />
            </div>
            <div>
                <Input
                    required
                    label="Email"
                    name="email"
                    value={formState.email}
                    type={"email"}
                    onChange={handleInputChange}
                />
            </div>
            <div>
                <Select
                    // disabled={}
                    name="roles"
                    selectedId={formState.roles}
                    label="Role"
                    optionAccessor="label"
                    onChange={(valObj) => {
                        if (valObj.roles === "Admin") {
                            handleInputChange({
                                ...valObj,
                                add_update_location_pk_id: user?.region_details.map(
                                    (region: any) => region.id
                                ),
                            });
                        } else handleInputChange(valObj);
                    }}
                    options={
                        !formState.id
                            ? ROLES.filter(
                                (role) =>
                                    role !== "Parcel Vendor" &&
                                    role !== "Depot Salesman"
                            ).map((key) => ({
                                label: key,
                                id: key,
                            }))
                            : ROLES.map((key) => ({
                                label: key,
                                id: key,
                            }))
                    }
                />
            </div>
            <div>
                <Select
                    multi={
                        formState.roles !== "Depot Salesman" &&
                        formState.roles !== "Parcel Vendor"
                    }
                    disabled={!formState.roles}
                    name="add_update_location_pk_id"
                    selectedId={formState.add_update_location_pk_id || ""}
                    label={
                        !formState.roles
                            ? "Center / Region"
                            : formState.roles === "Circulation Executive" ||
                                formState.roles === "Collection Executive" ||
                                formState.roles === "Depot Salesman" ||
                                formState.roles === "Parcel Vendor"
                                ? "Center"
                                : "Region"
                    }
                    optionAccessor="label"
                    onChange={handleInputChange}
                    options={!formState.roles ? generateDropDownOption(user?.region_details, "description") : getRegionOrCenters()}
                />
            </div>
            <div>
                <Input
                    required
                    maxLength={10}
                    label="Phone"
                    name="phone"
                    value={formState.phone}
                    type={"tel"}
                    onChange={handleInputChange}
                />
            </div>
            <div
                style={{
                    marginTop: "2rem",
                }}
            >
                <Button type="submit" disabled={!dirty}>
                    {formState.id ? "Update" : "Create"}
                </Button>
            </div>
        </form>
    );
};

const UserPage = () => {

    const { exec, data } = useQuery();
    const { exec: execUpdate, loading } = useQuery();
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<null | any>(null)

    const setUserStatus = (id: string) => {
        updateUserStatus(id).then(() => {
            alert("User activate succcessfully");
            exec(() => fetchUsers());
        })
    }

    const dataColumnDefs = [
        {
            headerName: "User ID",
            field: "id",
        },
        {
            headerName: "Name",
            field: "name",
            tooltipComponent: (params: any) => {
                return <div className="custom-tooltip">{params.value}</div>;
            },
        },
        {
            headerName: "Login ID",
            field: "login_id",
        },
        {
            headerName: "Email",
            field: "email",
        },
        {
            headerName: "Role",
            field: "roles",
        },
        {
            headerName: "Phone",
            field: "phone",
        },
        {
            headerName: "Status",
            field: "enabled",
            cellRenderer: (props: any) => {
                const { data } = props;
                return data.enabled ? "Active" : "Inactive";
            },
        },
        {
            headerName: "Action",
            cellRenderer: (props: any) => {
                const { data } = props;
                return (
                    <>
                        <FontAwesomeIcon
                            style={{
                                cursor: 'pointer'
                            }}
                            title="Edit User"
                            icon={faPen}
                            onClick={() => {
                                setSelectedUser(data);
                                setShowModal(!showModal);
                            }}
                        />
                        {data.enabled ? "" :
                            <FontAwesomeIcon
                                style={{
                                    cursor: 'pointer',
                                    color: "#36b16e",
                                    fontSize: "18px",
                                    marginLeft: "10px"
                                }}
                                title="Activate User"
                                icon={faToggleOn}
                                onClick={() => {
                                    setUserStatus(data.id)
                                }}
                            />
                        }
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        exec(() => fetchUsers());
    }, []);

    const handleUserUpdate = (data: IUser) => {
        const funcToExec = selectedUser ? updateUser : createUser;
        execUpdate(() =>
            funcToExec({
                id: data.id,
                email: data.email,
                login_id: data.login_id,
                name: data.name,
                password: data.password,
                phone: data.phone,
                roles: data.roles,
                type: USER_TYPE.internal,
                add_update_location_pk_id:
                    typeof data.add_update_location_pk_id === "number"
                        ? [data.add_update_location_pk_id]
                        : data.add_update_location_pk_id,
            }).then(() => {
                alert("User Created succcessfully");
                exec(() => fetchUsers());
                closeModal();
            })
        );
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    const openCreateUserModal = () => {
        setShowModal(true);
        setSelectedUser(null);
    };

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <div>
            <PageHeader
                header="Users"
                breadCrumb={["Dashboard", "Masters", "Users"]}
                rightSection={
                    <Button onClick={openCreateUserModal}> Create User</Button>
                }
            />
            <AgGridWrapper
                header={""}
                rowData={data || []}
                columnDefs={dataColumnDefs}
                exportData={false}
                search={true}
            />

            <Modal
                header={
                    selectedUser ? <h3>Update User</h3> : <h3>Create User</h3>
                }
                show={showModal}
                onClose={closeModal}
                loading={loading}
            >
                <UserForm
                    value={selectedUser || {}}
                    onSubmit={handleUserUpdate}
                />
            </Modal>
        </div>
    );
};

export default memo(UserPage);
