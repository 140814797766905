export const baseURL = `https://dms.expressindia.com/api`;
// export const baseURL = `http://165.22.216.0:8081/api`;
// export const baseURL = `http://192.168.80.152:8081`;
// export const baseURL = `http://192.168.80.179:8081`;
export const ROLES = [
    "Regional Manager",
    "Circulation Executive",
    "Collection Executive",
    "Parcel Vendor",
    "Depot Salesman",
    "Collection Backend Executive",
    "Collection Head",
    "Operation Head",
    "Dispatch Executive",
    "Branch Head",
    "West and South Head",
    "Admin",
    "City Head",
];
export const SUB_EMPLOYEE_USER_ROLES = [
    "Regional Manager",
    "Circulation Executive",
    "Collection Executive",
    "City Head",
];
export const ATTENDANCE_FLAG = [
    { label: "Present", id: "PRESENT" },
    { label: "Absent", id: "ABSENT" },
];
export const TYPE = [
    {
        label: "Depot",
        id: "DEPOT",
        value: "DEPOT",
    },
    {
        label: "Parcel Point",
        id: "PARCEL",
        value: "PARCEL",
    },
];
export const REVISION_TYPE = [
    {
        label: "Day",
        id: "DAY",
        value: "DAY",
    },
    {
        label: "Night",
        id: "NIGHT",
        value: "NIGHT",
    },
];
export const EMPLOYEE_TYPE = [
    {
        label: "Regional Manager",
        id: "Regional Manager",
        value: "Regional Manager",
    },
    {
        label: "Circulation Executive",
        id: "Circulation Executive",
        value: "Circulation Executive",
    },
    {
        label: "Collection Executive",
        id: "Collection Executive",
        value: "Collection Executive",
    },
];

export const REPORT_TYPE = [
    {
        name: "Branch wise daily sales report",
        id: "BRANCH_WISE_DAILY_SALES_REPORT",
        permittedRole: [
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
        ],
    },
    {
        name: "Region wise daily sales report",
        id: "REGION_WISE_DAILY_SALES_REPORT",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Executive wise daily sales report",
        id: "EXECUTIVE_WISE_DAILY_SALES_REPORT",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Depot Parcel wise daily sales report",
        id: "DEPOT_PARCEL_WISE_DAILY_SALES_REPORT",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Depot wise daily sales report",
        id: "DEPOT_WISE_DAILY_SALES_REPORT",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Circulation Exec-wise Supply",
        id: "CIRCULATION_EXE_WISE_SUPPLY",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Physical Return",
        id: "PHYSICAL_RETURN",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Attendance Report",
        id: "ATTENDANCE_REPORT",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },

    // collection
    {
        name: "Cityhead wise Collection",
        id: "CITYHEAD_WISE_COLLECTION",
        permittedRole: [
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Region wise Collection",
        id: "REGION_WISE_COLLECTION",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Executive wise Collection",
        id: "EXECUTIVE_WISE_COLLECTION",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Depot wise Collection",
        id: "DEPOT_WISE_COLLECTION",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Parcel wise Collection",
        id: "PARCEL_WISE_COLLECTION",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Payment Option wise",
        id: "PAYMENT_OPTION_WISE",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Coupon Status Wise",
        id: "COUPON_STATUS_WISE",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    // Print order
    {
        name: "Trade PO Revision",
        id: "PRINT_ORDER",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Trade PO Revision - Executivewise",
        id: "CIRCULATION_WISE_PRINT_ORDER",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "CityHead wise Print Order",
        id: "CITYHEAD_WISE_PRINT_ORDER",
        permittedRole: [
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
    {
        name: "Sampling Copy PO",
        id: "SAMPLE_COPY",
        permittedRole: [
            "Regional Manager",
            "Collection Backend Executive",
            "Collection Head",
            "Operation Head",
            "Dispatch Executive",
            "Branch Head",
            "Admin",
            "West and South Head",
            "City Head",
        ],
    },
];

export enum WEB_ROLES {
    "Regional Manager" = "Regional Manager",
    "Collection Backend Executive" = "Collection Backend Executive",
    "Collection Head" = "Collection Head",
    "Operation Head" = "Operation Head",
    "Dispatch Executive" = "Dispatch Executive",
    "Branch Head" = "Branch Head",
    "Admin" = "Admin",
    "West and South Head" = "West and South Head",
    "City Head" = "City Head",
}

export enum USER_TYPE {
    "internal" = "internal",
    "external" = "external",
}

export enum APPROVAL_STATUS {
    "PENDING" = "0",
    "APPROVED" = "1",
    "REJECTED" = "2",
    "VERIFIED" = "3",
}

export const ROLE_VS_PERMISSIONS: Record<
    Partial<WEB_ROLES>,
    {
        nps: boolean;
        supply: { view: boolean; edit: boolean };
        unsold_return: { view: boolean; edit: boolean };
        outstanding_collection: { view: boolean; edit: boolean };
        amount_collected: { view: boolean; edit: boolean };
        physical_return: { view: boolean; edit: boolean };
        showCollectionApprovals: boolean;
        showUnsoldApprovals: boolean;
        editTable: { unsold: boolean; collection: boolean };
        approveRejectTable: { unsold: boolean; collection: boolean };
        report: {
            view: boolean;
            regionDropdown: boolean;
            branchDropdown: boolean;
        };
        attendance_summary: boolean;
    }
> = {
    [WEB_ROLES["Admin"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: true,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: true,
    },
    [WEB_ROLES["Branch Head"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: false,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: false },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: true,
    },
    [WEB_ROLES["Collection Backend Executive"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: true,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: true,
    },
    [WEB_ROLES["Collection Head"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: true,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: false,
    },
    [WEB_ROLES["Dispatch Executive"]]: {
        nps: false,
        outstanding_collection: { view: false, edit: false },
        amount_collected: { view: false, edit: false },
        showUnsoldApprovals: false,
        showCollectionApprovals: false,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: false },
        physical_return: { edit: true, view: true },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: false, regionDropdown: true, branchDropdown: true },
        attendance_summary: false,
    },
    [WEB_ROLES["Operation Head"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: true,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: true,
    },
    [WEB_ROLES["Regional Manager"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: false },
        showUnsoldApprovals: true,
        showCollectionApprovals: false,
        supply: { view: true, edit: true },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: false },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: false },
        attendance_summary: true,
    },
    [WEB_ROLES["West and South Head"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: true,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: true },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: true },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: true },
        attendance_summary: true,
    },
    [WEB_ROLES["City Head"]]: {
        nps: true,
        outstanding_collection: { view: true, edit: false },
        amount_collected: { view: true, edit: true },
        showUnsoldApprovals: true,
        showCollectionApprovals: false,
        supply: { view: true, edit: false },
        unsold_return: { view: true, edit: false },
        physical_return: { edit: false, view: false },
        editTable: { unsold: true, collection: false },
        approveRejectTable: { unsold: true, collection: true },
        report: { view: true, regionDropdown: true, branchDropdown: false },
        attendance_summary: true,
    },
};

export const defaultColsDef = {
    flex: 1,
    // minWidth: 50,
    cellStyle: {
        display: "flex",
        alignItems: "center",
        lineHeight: 1.5,
        paddingTop: "2px",
        paddingBottom: "4px",
    },
    sortable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    wrapText: true,
    autoHeight: true,
    lockPinned: true,
};
