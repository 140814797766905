import React, { ForwardedRef } from "react";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import Button from "../../base/Button";
import { customDateFormat } from "../../../utils";

// Define types for the props
interface ExportReactCSVProps {
    csvHeaders: { header: string, key: string, valueGetter: (params: any) => any; }[];
    // csvHeaders: { header: string, key: string, headerName: string, field: string, valueGetter: (params: any) => any; }[]; // Array of objects for header and key
    csvData: any[]; // Array of data for each row
    fileName: string;
}

// ForwardRef is typed with the component's props and ref type
const ExportExcel = React.forwardRef<HTMLButtonElement, ExportReactCSVProps>(
    ({ csvHeaders, csvData, fileName }, ref: ForwardedRef<HTMLButtonElement>) => {
        const columns = csvHeaders.filter(el => el.key !== "srNo");
        const data = csvData;
        const workSheetName = 'Worksheet-1';
        const workbook = new Excel.Workbook();
        const computeData = (rowData: any) => {
            const keys = Object.keys(rowData);
            let newRowData: any = {};
            if (csvHeaders.length > 0) {
                columns.forEach(header => {
                    if (keys.includes(header.key)) {
                        const newValue = header.hasOwnProperty('valueGetter')
                            ? header.valueGetter({ data: rowData })
                            : rowData[header.key];
                        newRowData[header.key] = newValue;
                    }
                });
                return newRowData;
            }
            return {}
        }

        const saveExcel = async () => {
            try {
                // Create a worksheet in the workbook
                const worksheet = workbook.addWorksheet(workSheetName);

                // Add worksheet columns, with header and key mappings
                worksheet.columns = columns;

                // Update the font for the first row
                worksheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dbdbdb' },
                    bgColor: { argb: 'dbdbdb' },
                };
                worksheet.getRow(1).font = { bold: true };

                // Set alignment and width for each column
                worksheet.columns.forEach((column: any) => {
                    column.width = column.header.length + 10;
                    column.alignment = { horizontal: 'left' };
                });

                // Add rows to the worksheet
                data.forEach((singleData: any) => {
                    const computeData1 = computeData(singleData);
                    worksheet.addRow(computeData1);
                });

                // Write the content as a buffer and save the file
                const buf = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buf]), `${fileName}-${customDateFormat(new Date())}.xlsx`);
            } catch (error) {
                console.error('Error while exporting:', error);
            } finally {
                // Remove the worksheet after exporting
                workbook.removeWorksheet(workSheetName);
            }
        };

        return (
            <Button ref={ref} onClick={saveExcel}>
                Export
            </Button>
        );
    }
);



export default ExportExcel;
