import React, { FC, ReactNode } from "react";
import styles from "./tableHeader.module.scss";

export interface ITableHeader {
    header: string | ReactNode;
    rightSection?: ReactNode;
    subHeader?: ReactNode;
}
const TableHeader: FC<ITableHeader> = ({
    header,
    rightSection,
    subHeader,
}) => {
    return (
        <div className={styles.wrapper}>
            <section className={styles.left}>
                <h3>{header}</h3>
                {subHeader && subHeader}
            </section>
            <section className={styles.right}>{rightSection}</section>
        </div>
    );
};

export default TableHeader;
