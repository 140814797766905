
import React, { useEffect, useState } from "react";
import {
    defaultColsDef,
    ROLE_VS_PERMISSIONS,
    ROLES,
    WEB_ROLES,
} from "../../../constants";
import { useFormState, useQuery } from "../../../hooks";
import {
    getDefaultCollectionList,
} from "../../../state/actions/dashboard.action";
import Button from "../../base/Button";
import PageHeader from "../../base/PageHeader";
import styles from "./collectionPage.module.scss";
import {
    customDateTimeFormat,
    subtractNDays,
} from "../../../utils";
import { AgGridReact } from "ag-grid-react";
import TableHeader from "../../base/TableHeader";
import { useAuthContext } from "../../../context/auth";

const CollectionSummary = () => {
    const { user } = useAuthContext();
    const role: WEB_ROLES = user?.roles;
    const userID: string = user?.id;

    const [pageState, setPageState] = useFormState<{
        startDate: Date;
        filtersApplied: boolean;
    }>({
        startDate: new Date(),
        filtersApplied: false,
    });

    const { exec, data } = useQuery();

    const collectionTableColConfig = [
        ...(ROLES.includes(role) && (role === "Admin" || role === "West and South Head")
            ? [
                {
                    headerName: "Branch Name",
                    field: "city",
                },
            ]
            : []),
        ...(ROLES.includes(role) && (
            role === "City Head" ||
            role === "Operation Head" || role === "Branch Head" || role === "Collection Backend Executive")
            ? [
                {
                    headerName: "Division",
                    field: "division",
                }
            ]
            : [])
        ,
        ...(ROLES.includes(role) && role === "Regional Manager"
            ? [
                {
                    headerName: "Executive Name",
                    field: "executiveName",
                },
                {
                    headerName: "Depot/Parcel Name",
                    field: "name",
                },
            ]
            : []),

        {
            headerName: "Depot/Parcel",
            field: "type"
        },
        {
            headerName: "Total Outstanding (₹)",
            field: "total_closing_balance",
        },
        { headerName: "Todays Invoice", field: "daily_billing" },
        { headerName: "Payment Received (₹)", field: "total_amount_collected" },
        { headerName: "Accepted Coupons", field: "accepted_coupon" },
        ...(ROLES.includes(role) && (
            role === "City Head" ||
            role === "Operation Head" || role === "Branch Head" || role === "Collection Backend Executive")
            ? [
                { headerName: "Rejected Coupons", field: "rejected_coupon" },
            ]
            : []),

    ];

    const fetchListing = (withFilter: boolean = true) => {
        return exec(() => getDefaultCollectionList(
            pageState.startDate,
            userID
        )
        );
    }

    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();

    };

    useEffect(() => {
        fetchListing();
    }, [pageState.startDate])
    return (
        <>

            <TableHeader
                header={"Summary"}
                rightSection={
                    <div className="filter_field_date">
                        <input
                            type={"date"}
                            name="startDate"
                            value={
                                pageState.startDate
                                    .toISOString()
                                    .split("T")[0]
                            }
                            // max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                                setPageState({
                                    startDate: new Date(
                                        e.currentTarget.value
                                    ),
                                });
                            }}
                        />
                    </div>
                }
            />
            <div className="ag-theme-alpine ag_grid_table_wrapper_low">
                <AgGridReact
                    suppressRowTransform={true}
                    rowData={data}
                    columnDefs={collectionTableColConfig}
                    defaultColDef={defaultColsDef}
                    onGridReady={onGridReady}
                />
            </div>
        </>
    );
};

export default CollectionSummary;
